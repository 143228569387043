import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GoldRateDTO } from "../models/goldRate";
import { ProductsDTO } from "../models/products";
import { ProductsRepository } from "../repositories/productRepository";
import { StoreSelector } from "../store/store";
import { setProductDetails } from "../store/productDetailsSlice";



export const useProductHook = () => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const product = useSelector((state: StoreSelector) => state.productDetails);
    useEffect(() => {
      if (id) {
        ProductsRepository.getProductById(id).then((product: any) => {
          dispatch(setProductDetails(product));
        });
      }
     
    }, [id]);
    const rate = useSelector((state: StoreSelector) => state.goldRate);
    const goldRate = GoldRateDTO.fromJSON(rate);
    return {product, goldRate}
};
