import { css , StyleSheet} from "aphrodite";
import { CustomersDTO } from "../../../models/customers";
import { ConfigProvider, Pagination } from 'antd';
import { getID } from "./editcustomer.component";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { IoMdSearch } from "react-icons/io";
import { darkAmberColor, softBlack, white } from "../../../theme";
import { MdDeleteOutline } from "react-icons/md";
import commonCss, { color, width } from "../../../commonCss/commonCss";
import { useCustomersHook } from "../../../hooks/useCustomersHook";



export default function ListCustomers(){
    const navigate = useNavigate();
    const {customers, currentPage, onChangeSearchValue, deleteCustomerByIndex, setCurrentPage } = useCustomersHook();
 
    return<div className={css(styles.container)}>

            <div className={css(styles.searchdiv)}>
                <IoMdSearch style={{width:'40px'}} color={darkAmberColor} size='1.5rem' />
                <input key={"Sa"} type="search" placeholder="Search By Name or Contact" onChange={(e)=>{onChangeSearchValue(e);}} className={css(styles.input)}/>
            </div>

            <div className={css(styles.resultdiv)}>
                {currentPage.map((_customer, index) => {
                    const customer = CustomersDTO.fromJSON(_customer);
                    return <li key={customer.docId} className={css(styles.list,commonCss.flexRow,commonCss.margin10,color(softBlack))}>
                    <div style={{width:'30%'}}>{customer.name}</div>
                    <div>{customer.contact}</div> 
                    <div className={css(commonCss.flexRow,commonCss.alignItemsCenter,commonCss.justifySpaceBetween,width('13%'))}>
                        <button style={{border:'none',backgroundColor:'white', padding:'0px'}} onClick={()=>{getID(customer);navigate('/editcustomer');}}>
                            <FiEdit className={css(styles.button)} color= {darkAmberColor} size='1.1rem' />
                        </button>
                        <button style={{border:'none',backgroundColor:'white', padding:'0px'}} onClick={()=>{deleteCustomerByIndex(index)}}>
                            <MdDeleteOutline className={css(styles.button)} color= {darkAmberColor} size='1.3rem' />
                        </button>
                    </div>
                </li>
                })}
            </div>

            <div className={css(styles.pagination)}>
                <ConfigProvider theme={{token:{
                                                colorPrimary: darkAmberColor,
                                                controlItemBgActive: white,
                                            }}} >
                    <Pagination
                        style={{fontSize:'17px'}}
                        total={customers.length}
                        showQuickJumper
                        showTotal={(total) => `Total  ${total}  Customers`}
                        onChange={(page,pageSize)=>{
                            const startIndex = (page-1)*9
                            const endIndex = (page*9)-1;
                            if(page*10 <= customers.length){
                                setCurrentPage(customers.slice(startIndex,endIndex));
                            }else{
                                setCurrentPage(customers.slice(startIndex,));
                            }
                    }}/>
                </ConfigProvider>
            </div>
    </div>

}

const styles=StyleSheet.create({

    container:{
        display:'grid',
        gridTemplateRows:'1fr 12fr 1fr'
    },

    searchdiv:{
        paddingLeft:'5px',
        boxShadow: '0px 0px 3px #D9D9D9',
        display: 'flex',
        alignItems:'center',
    },

    input:{
        padding:'15px',
        fontSize:'15px',
        paddingLeft:'5px',
        color:softBlack,
        border:'none',
        width:'95%',
        ':focus':{outline:'none'},
    },

    resultdiv:{
        marginBottom:'20px',
        marginTop:'10px',
    },

    list:{
        display: "flex",
        padding:'0.5rem',
        color:'grey',
        fontSize:'17px',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '0px 0px 2px #D9D9D9',
    },

    button:{
        backgroundColor:'white',
        transitionDuration:'0.3s',
        padding:'0.2rem',
        ':hover':{color:softBlack},
    },

    pagination:{
        display:'flex',
        alignContent:'center',
        justifyContent:'space-around',
        padding:'7px',
    }

    
})    
