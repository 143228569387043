import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TagState = {
  id: string,
  karat: string,
  category: string,
  subCategory: string,
  name: string,
  count: number,
}



const initialState: TagState[] = [];

const productTagSlice = createSlice({
  name: 'productTags',
  initialState,
  reducers: {
    setTag: (state, action: { payload: TagState[] }) => {
      state = action.payload;
      return state;
    },
    resetTag: (state) => {
      state = initialState;
    }
  }

});



export const { setTag, resetTag } = productTagSlice.actions;
export default productTagSlice.reducer;