
import { DocumentData, where } from "firebase/firestore";
import { Query } from "../library/query";
import { ProductsDTO } from "../models/products";
import { ProductStateData } from "../store/productSlice";
//import { ProductsDTO } from "../models/products";
//import { setDoc } from "firebase/firestore";

export class ProductsRepository {
    private static productCollection = Query.getCollection('products_digital');


    static async getAllProducts(): Promise<ProductStateData[]> {
      return await this.productCollection.getAllDocs() as ProductStateData[];
    }

  static async addProduct(data: ProductsDTO) {
    try {
      const productData = data.toJson();
      const docRef = await this.productCollection.addNewDoc({ ...productData });
      console.log("Product added with ID:", docRef.id);
      return docRef;
    } catch (error) {
      console.error("Error adding product:", error);
      throw error;
    }
  }
  static async editProduct(id: string, data: any) {
    console.log("Editing product with id and data:", id, data); // Log id and data
    if (!id || !data) {
      throw new Error("ID or data is empty or invalid");
    }
    return await this.productCollection.updateDocById(id, { ...data });
  }

    static async deleteProduct(id: string) {
        return await this.productCollection.removeDocById(id);  
    }

    static async getProductById(id:string):Promise<DocumentData | null>{
        return await this.productCollection.getDocById(id)
    }
    
    static async searchProductByTag(tag: string) {
        // Convert value to lowercase for case-insensitive search, assuming your productName field is also in lowercase
        const upperCaseTag = tag.toUpperCase();
        
        // Adjust where condition for partial matches (this is pseudo-code and depends on your database)
        const whereConditions = [
            // where('productName', '>=', upperCaseValue),
            // where('productName', '<', upperCaseValue + '\uf8ff') // This ensures we only get results starting with the value
            where('productTagNumber', '==', upperCaseTag),
        ];
        
        return await this.productCollection.searchDoc(whereConditions);
    }
    
    
}
