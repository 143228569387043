
import { css, StyleSheet } from "aphrodite";
import { IoMdSearch } from "react-icons/io";
import { darkAmberColor, softBlack } from "../../theme";



export const Search = ({onChangeSearchValue}:{onChangeSearchValue:(e:any) => void}) => {
    return (
        <div className={css(style.searchdiv)}>
        <IoMdSearch
          style={{ width: "40px" }}
          color={darkAmberColor}
          size="1.5rem"
        />
        <input
          type="search"
          placeholder="Search By Name"
          onChange={(e) => {
            onChangeSearchValue(e);
          }}
          className={css(style.searchInput)}
        />
      </div>
    )
}

const style = StyleSheet.create({
    searchdiv: {
        width: "1300px",
        marginLeft: "40px",
        paddingLeft: "5px",
        boxShadow: "0px 0px 3px #D9D9D9",
        alignItems: "center",
      },
      searchInput: {
        padding: "15px",
        fontSize: "15px",
        paddingLeft: "5px",
        color: softBlack,
        border: "none",
        width: "95%",
        ":focus": { outline: "none" },
      }
    });