import { useCallback, useEffect, useRef, useState } from 'react';
import { ProductsDTO } from "../models/products";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { fireStorage } from '../config/firestore';
import { ProductsRepository } from '../repositories/productRepository';
import {setProductDetails, setPreview, resetProduct, ProductDetailsState} from '../store/productDetailsSlice';
import { ProductTagRepository } from '../repositories/productTagRepository';
import { setTag, resetTag, TagState} from '../store/productTagSlice';
import { TagsDTO } from '../models/tags';
import { setError } from '../store/errorSlice';
import { setLoading } from '../store/loadingSlice';

const generateTagFor = (id: string, tags: TagState[]) => {
    const tagData = tags.filter(tag => tag.id === id);
    console.log("id:", id);
    console.log("tags:", tags);
    console.log("Tag data:", tagData);

    if (tagData.length) {
        const tag = tagData[0].id + (tagData[0].count +1);
        return tag;
    } else
        return '';
}

export const useProductState = () => {
    const dispatch = useDispatch<AppDispatch>();
    const productDetailsState: ProductDetailsState = useSelector((state: RootState) => state.productDetails);
    const tagsState = useSelector((state: RootState) => state.tags)
    const fileRef = useRef({} as File);

   


    useEffect(() => {
        ProductTagRepository.getAllTagsCollection().then((tags) => {
        console.log("Tags:", tags);
        dispatch(setTag(tags as TagState[]));
        });
    }, []);

    return {productDetailsState, tagsState, fileRef};

}


export const useProductActions = ({productDetailsState, tagsState}: {productDetailsState: ProductDetailsState, tagsState: TagState[]}) => {

    const dispatch = useDispatch<AppDispatch>();

    const {productDetails} = productDetailsState;



    const setTagOnProduct = (id: string) => {
        const tag = generateTagFor(id, tagsState);
        dispatch(setProductDetails({productTagNumber: tag}));
    };

    const updateProductDetails = (data: { name: keyof typeof productDetailsState['productDetails'], value: string | number | boolean }) => {
        dispatch(setProductDetails({[data.name] : data.value}));
    };


    const handleReset = () => {
        dispatch(resetProduct());
    };

            
    const handleDrop = useCallback((fileRef: any, acceptedFiles: File[]) => {
        if (acceptedFiles.length) {
        const file = acceptedFiles[0];
            fileRef.current = file;
            dispatch(setPreview(URL.createObjectURL(file)));
        }
    }, []);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, fileRef:any) => {
        if (e.target.files && e.target.files[0]) {
            fileRef.current = e.target.files[0];
            dispatch(setPreview(URL.createObjectURL(e.target.files[0])));
        }
    };

    const uploadImageToFirebase = async (file: File) => {
        const storageRef = ref(fireStorage, `products/${file.name}`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        return downloadURL;
    };



    const saveProduct = async (fileRef: any) => {
        
        if (fileRef.current && Object.keys(productDetails).length) {
            try {
                dispatch(setLoading(true));
                const imageUrl = await uploadImageToFirebase(fileRef.current);
                updateProductDetails({ name: 'image', value : imageUrl});
                const updatedProductDetails = {...productDetails,image: imageUrl};
                await ProductsRepository.addProduct(ProductsDTO.fromJSON(updatedProductDetails));
                await ProductTagRepository.incrementTagCountById(updatedProductDetails.productTagNumber);
                alert('Product is successfully saved');
            } catch (error) {
                console.error("Error uploading image: ", error);
                if (error instanceof Error) {
                    dispatch(setError(error.message));
                } else {
                    dispatch(setError("An unknown error occurred."));
                }
            } finally {
                ProductTagRepository.getAllTagsCollection().then((tags) => {
                    dispatch(setTag(tags as TagState[]));
                });
                dispatch(setLoading(false));
                handleReset();
            }
        } else {
        alert('Please upload an image.');
        }
    };

    return {updateProductDetails, handleDrop, handleFileChange, saveProduct, setTagOnProduct, handleReset};
};
