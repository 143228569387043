import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import goldRateReducer, {GoldRateStateData} from "./goldRateSlice";
import userReducer, {UserDetails} from "./userSlice";
import productReducer ,{ProductStateData} from "./productSlice";
import productDetailsReducer, { ProductDetailsState } from "./productDetailsSlice";
import productTagsReducer, { TagState } from "./productTagSlice";
import loadingReducer from "./loadingSlice";
import errorReducer from "./errorSlice";
//import { useDispatch } from "react-redux";

import { User } from "firebase/auth";
import customersReducer, { CustomerStateData } from "./customersSlice";
import { error } from "console";
export type StoreSelector = {
    auth: User,
    goldRate: GoldRateStateData,
    user: UserDetails,
    products:ProductStateData[],
    productDetails: ProductDetailsState,
    customers: CustomerStateData[],
    tags: TagState[],
}

const store = configureStore({
    reducer: {
        auth: authReducer,
        goldRate: goldRateReducer,
        user: userReducer,
        products:productReducer,
        customers: customersReducer,
        productDetails: productDetailsReducer,
        tags : productTagsReducer,
        loading: loadingReducer,
        error: errorReducer,
    },
})
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

// Hook to use `AppDispatch` type
export default store;