import { CustomerStateData } from "../store/customersSlice"

interface CustomerConstructorObjectInterface{
    _name: string, _contact: string, _age: string, _locality: string, _city: string, _state: string, _pincode: string, _country: string, _gender: string, _email?: string, _membershipId?: string, _docId: string
}

export class CustomersDTO {
    private constructor(private customerData: CustomerConstructorObjectInterface) {}

    get firstName() {
        if (!!this.customerData._name)
            return this.customerData._name.split(" ")[0]
        return ""
    }

    get lastName() {
        if (!!this.customerData._name) {
            const nameArr = this.customerData._name.split(" ")
            return (nameArr.length>=2) ? nameArr[nameArr.length-1] : ""
        }
        return ""
    }

    get name() {
        return this.customerData._name
    }

    get contact() {
        return !!this.customerData._contact ? `+91${this.customerData._contact}` : this.customerData._contact
    }

    get email(){
        return this.customerData._email
    }

    get gender() {
        return this.customerData._gender
    }

    get age() {
        return this.customerData._age
    }

    get locality() {
        return this.customerData._locality
    }

    get city() {
        return this.customerData._city
    }

    get state() {
        return this.customerData._state
    }

    get country(){
        return this.customerData._country
    }

    get pincode(){
        return this.customerData._pincode
    }

    get address() {
        return `${this.customerData._locality}, ${this.customerData._city}, ${this.customerData._state}, ${this.customerData._country}, ${this.customerData._pincode}`
    }

    get membershipId(){
        return this.customerData._membershipId
    }

    get docId(){
        return this.customerData._docId
    }

    static fromJSON(data: any): CustomersDTO {
        return new CustomersDTO({
            _name: data.name,
            _contact: data.contact, 
            _age: data.age, 
            _locality: data.locality, 
            _city: data.city, 
            _state: data.state, 
            _pincode: data.pincode, 
            _country: data.country, 
            _gender: data.gender,
            _email: data.email,
            _membershipId: data.membershipId,
            _docId: data.id
        })
    }

    toJson(): CustomerStateData {
        if (!!this.contact) {
            return {
                name: this.name,
                contact: this.contact,
                age: this.age,
                gender: this.gender,
                locality: this.locality,
                state: this.state,
                country: this.country,
                pincode: this.pincode,
                city: this.city,
                email: this.email,
                membershipId: this.membershipId
            }
        }
        return {} as CustomerStateData
        
    }

    toAddToWANNotifier() {
        if (!!this.contact) {
            return {
                first_name: this.firstName,
                last_name: this.lastName,
                whatsapp_number: this.contact,
                lists: [
                    "customers"
                ],
                tags: [
                    "in-store-purchase"
                ],
                status: "subscribed",
                replace: false
            }
        }
    }

    toVCARD() {
        if (!!this.contact) {
            return `BEGIN:VCARD
VERSION:3.0
FN:${this.firstName} customer
TEL:${this.contact}
EMAIL:
END:VCARD`
        }
    }

}