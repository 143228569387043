import { ProductsRepository } from '../repositories/productRepository';
import { deleteProduct, ProductStateData, setProducts } from '../store/productSlice';
import { useDispatch, useSelector } from 'react-redux';
import { StoreSelector } from "../store/store";
import { useCallback, useEffect, useState } from 'react';


const getDebounce = (callbackFunction: Function) =>
    setTimeout(() => {
        callbackFunction()
    }, 800);

let timer: any = null;

export const useProductsHook = () => {
    const dispatch = useDispatch();
    const products = useSelector((state: StoreSelector) => state.products);
    const [currentPage, setCurrentPage] = useState([] as ProductStateData[]);
    const searchProducts = (searchedValue: string) => {
        ProductsRepository.searchProductByTag(searchedValue).then((_products: any) => {
            dispatch(setProducts(_products));
            setCurrentPage(_products.length <= 9 ? _products : _products.slice(0, 9));
        });
    }
    const showAllProduct = useCallback(() => {
        ProductsRepository.getAllProducts().then(_products => {
            console.log("Fetched products:", _products);
            dispatch(setProducts(_products))
            setCurrentPage(_products.length <= 9 ? _products : _products.slice(0, 9));

        });
    }, []);

    function onChangeSearchValue(e: any) {
        const searchedValue = e.target.value;
        if (timer) {
            clearTimeout(timer)
        }
        if (searchedValue !== '') {
            timer = getDebounce(() => searchProducts(searchedValue))
        } else {
            timer = getDebounce(showAllProduct)
        }
    }

    function deleteProductByIndex(index: number) {
        console.log("deleteProductByIndex called with index:", index);
        console.log("Current products:", products);
        if (products && index >= 0 && index < products.length) {
            const confirmed = window.confirm("Are you sure?");
            if (confirmed) {
                ProductsRepository.deleteProduct(products[index].id as string)
                    .then(() => dispatch(deleteProduct(index)))
                    .catch(error => {
                        console.error("Error deleting product:", error);
                    });
            }
        } else {
            console.error("Invalid product index:", index);
        }
    }
    useEffect(() => {
        if (products.length === 0) { 
            showAllProduct()
        } else {
            console.log("i else", products)
            setCurrentPage(products.length <= 9 ? products : products.slice(0, 9));
        }
    }, []);

    return { products, currentPage, setCurrentPage, deleteProductByIndex, onChangeSearchValue };
};
