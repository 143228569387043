import { css, StyleSheet} from "aphrodite";
import commonCss, { maxWidth, minWidth, padding } from "../../../commonCss/commonCss";
import { primaryColor, darkAmberColor, softBlack, silverColor } from "../../../theme";
import { AutoComplete } from 'antd';
import { useState } from "react";
import { ButtonMedium } from "../../button.component";
import { MdDeleteOutline } from "react-icons/md";
import { ProductsRepository } from "../../../repositories/productRepository";
import { ProductsDTO } from "../../../models/products";
import { useBillingHook } from "../../../hooks/useBillingHook";
import { AiOutlineGold } from "react-icons/ai";
import { GiCutDiamond } from "react-icons/gi";

const getDebounce = (callbackFunction: Function) => 
    setTimeout(() => {
        callbackFunction()
    }, 500);

let timer:any = null;

export default function JeweleryBilling(){

    const {enterGoldItems,enterDiamondItems} = useBillingHook();

    const [tagOptions, setTagOptions] = useState<{value:string}[]>([])


    const [selectedProduct, setSelectedProduct] = useState<ProductsDTO>()
    const [products, setProducts] = useState<ProductsDTO[]>([])

    // const [items, setItems] = useState([])

    const [goldItems,setGoldItems] = useState<{productName:string, productTagNumber:string, goldKarat:string, goldWeight:number, makingCharges:number}[]>([])
    const [diamondItems,setDiamondItems] = useState<{productName:string, productTagNumber:string, diamondPieces:number, diamondSize:string, makingCharges:number, diamondWeight:number}[]>([])

    function addItem(){
        if(selectedProduct){
            if(selectedProduct.diamondPieces){
                const {productName,productTagNumber,diamondPieces,diamondSize,makingCharges,diamondWeight} = selectedProduct
                setDiamondItems([...diamondItems,{productName,productTagNumber,diamondPieces,diamondSize,makingCharges,diamondWeight}])
                enterDiamondItems(diamondItems)
            }else{
                const {productName,productTagNumber,goldKarat,goldWeight,makingCharges} = selectedProduct
                setGoldItems([...goldItems,{productName,productTagNumber,goldKarat,goldWeight,makingCharges}])
                enterGoldItems(goldItems)
            }
        }
    }

    function removeItem(item: {productName:string, productTagNumber:string, goldKarat:string, goldWeight:number, makingCharges:number} | {productName:string, productTagNumber:string, diamondPieces:number, diamondSize:string, makingCharges:number, diamondWeight:number},
                        list:string){   
        if(list==='gold'){
            const newList = goldItems.filter((element)=>element !== item);
            setGoldItems([...newList])
        }else{
            const newList = diamondItems.filter((element)=>element !== item);
            setDiamondItems([...newList])   
        }
        
    }

    function searchProduct(tag:string){
        if(tag){
            ProductsRepository.searchProductByTag(tag).then((products: any[]) => {
                const productsDTO = products.map((product: any) => ProductsDTO.fromJSON(product))
                setProducts(productsDTO)
                setTagOptions(productsDTO.map((product: { productTagNumber: any; }) =>({value: product.productTagNumber})))
            })
        }

    }

    function handleSearch(tag: string){

        setTagOptions([]);

        const searchedValue = tag;
        if (timer) {
            clearTimeout(timer)
        }
        if(searchedValue!==''){
            timer = getDebounce(() => searchProduct(searchedValue))
        }
    }

    function handleSelect(tagNumber:any){
        setSelectedProduct(products.find((product) => product.productTagNumber === tagNumber))
    }

    return<div className={css(styles.frame, padding('1.6rem'))}>

    <div className={css(styles.title)}>Jewellery</div>

    <div className={css(styles.input)}>

        <AutoComplete
            style={{ width: '100%' }}
            options={tagOptions}
            placeholder="Tag No."
            filterOption={true}
            onSearch={handleSearch}
            onSelect={handleSelect}
        />

    </div>
    <div className={css(styles.preview)}>
        <div>
            {selectedProduct?.productName} | {selectedProduct?.goldKarat} {selectedProduct?.goldWeight}
            {selectedProduct?.diamondSize} {selectedProduct?.diamondPieces} {selectedProduct?.diamondWeight} | {selectedProduct?.productTagNumber}
        </div>
        <div className={css(commonCss.flexRow,commonCss.alignItemsCenter)}>
            <ButtonMedium text={"Add Item"} onClick={addItem} color={primaryColor} className={css(styles.saveButton)} size='6rem'/>
        </div>
    </div>
    <div className={css(styles.input)}>
        <div style={{width:'100%'}}>
            {goldItems.map((item, index) => (
                <li key={index} className={css(styles.li)}>
                    <div><AiOutlineGold color={darkAmberColor}/></div>
                    <div style={{width:'80%'}}>{item.productName} {item.goldWeight} {item.goldKarat}</div>
                    <button style={{border:'none',backgroundColor:'white', padding:'0px'}} onClick={()=>{removeItem(item,'gold')}}>
                        <MdDeleteOutline className={css(styles.liButton)} color= {darkAmberColor} size='1.3rem' />
                    </button>
                </li>
            ))}
            {diamondItems.map((item, index) => (
                <li key={index} className={css(styles.li)}>
                    <div><GiCutDiamond /></div>
                    <div style={{width:'80%'}}>{item.productName} {item.diamondPieces}</div> 
                <button style={{border:'none',backgroundColor:'white', padding:'0px'}} onClick={()=>{removeItem(item,'diamond')}}>
                    <MdDeleteOutline className={css(styles.liButton)} color= {darkAmberColor} size='1.3rem' />
                </button>
            </li>
            ))}
        </div>
          
    </div>

</div>
}

const styles= StyleSheet.create({

    frame:{
        color: primaryColor,
        flex: 1,
        boxShadow: '0px 1px 5px #aaa',
        fontSize:'1.1rem',

    },

    title:{
        padding:'2rem',
        fontWeight:'bold',
        boxShadow: '0px 0px 1px #aaa',
        textAlign:'center',
    },

    input:{
        display: "flex",
        padding:'0.9rem',
        color:'grey',
        justifyContent: 'space-between',
        alignItems: 'center',
        // flexWrap:'wrap'
    },

    saveButton:{
        borderRadius: '20px',
        fontWeight: 'bold',
        // width:'auto',
        
    },

    preview:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
    },

    li:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between'
    },

    liButton:{
        backgroundColor:'white',
        transitionDuration:'0.3s',
        padding:'0.2rem',
        ':hover':{color:softBlack},
    },


})
