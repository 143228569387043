import { css , StyleSheet} from "aphrodite";
import commonCss, { padding, colsGap, rowsGap } from "../../commonCss/commonCss";
import { primaryColor } from "../../theme";
import { ConfigProvider } from 'antd';
import CustomerDetails from "./billingsubcomponents/customerbilling";
import { useBillingHook } from "../../hooks/useBillingHook";
import { FaRupeeSign } from "react-icons/fa";
import JeweleryBilling from "./billingsubcomponents/jewelerybilling.component";





  




export default function Billing(){
    const {bill} = useBillingHook();
    const {igstGold, cgstGold, sgstGold, igstDiamond, cgstDiamond, sgstDiamond, totalBasePriceDiamond, totalBasePriceGold, totalMakingChargesDiamond,totalMakingChargesGold} = bill;
    

    return<div className={css(commonCss.flexColumn,commonCss.justifySpaceBetween, colsGap('30px'), rowsGap("30px"))}>

        <ConfigProvider theme={{token:{colorPrimary: primaryColor}}}>
             
            <CustomerDetails />
            <div className={css(commonCss.flexRow, commonCss.justifySpaceBetween, commonCss.flexWrap, colsGap('30px'), rowsGap('30px'))}>
                <JeweleryBilling />
            </div>

        </ConfigProvider>

        <div className={css(styles.frame, padding('1.6rem'))}>

            <div className={css(styles.divli)}>
                <span>CGST 1.5%</span>
                <span style={{display:'flex',alignItems:'center'}}><FaRupeeSign />{cgstGold+cgstDiamond}</span>
            </div>

            <div className={css(styles.divli)}>
                <span>SGST 1.5%</span>
                <span style={{display:'flex',alignItems:'center'}}><FaRupeeSign />{sgstGold+sgstDiamond}</span>
            </div>

            <div className={css(styles.divli)}>
                <span>IGST 3%</span>
                <span style={{display:'flex',alignItems:'center'}}><FaRupeeSign />{igstGold+igstDiamond}</span>
            </div>

            <div className={css(styles.divli)}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <span>Discount</span>
                    <input type='text' className={css(styles.input)}></input>%
                </div>
                
                <span style={{display:'flex',alignItems:'center'}}><FaRupeeSign />discounted amount here</span>
            </div>
            
            <div className={css(styles.divli)}>
                <span>Total </span>
                <span style={{display:'flex',alignItems:'center'}}><FaRupeeSign />{cgstGold+cgstDiamond+igstGold+igstDiamond+sgstGold+sgstDiamond+totalBasePriceDiamond+totalBasePriceGold+totalMakingChargesDiamond+totalMakingChargesGold}</span>
            </div>

            <div className={css(styles.divli)}>
                <span></span>
            </div>
            
        </div>

    </div>
    

}



const styles= StyleSheet.create({

    frame:{
        color: primaryColor,
        flex: 1,
        boxShadow: '0px 1px 5px #aaa',
        fontSize:'1.1rem',

    },
    
    title:{
        padding:'5%',
        fontWeight:'bold',
        boxShadow: '0px 0px 1px #aaa',
        textAlign:'center',
    },

    input:{
        color:'grey',
        fontSize:'15px',
        alignItems: 'center',
        marginLeft:'5px',
        marginRight:'5px',
        width:'20px',
        boxShadow: '0px 0px 1px #aaa',
        border:'none',
        ':focus':{outline:'none'},
    },

    divli:{
        display: "flex",
        padding:'0.9rem',
        color:'grey',
        fontSize:'15px',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
})