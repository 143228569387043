import Navbar from "./components/navbar.component";
import './App.css';
import { Provider } from 'react-redux';
import store from './store/store';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import HomePage from './scenes/HomePage.scenes';
import LoginPage from './scenes/LoginPage.scenes';
import ContactUsPage from './scenes/ContactUsPage.scenes';
import QRScanPage from './scenes/QRScanPage.scenes';
import ServicesPage from './scenes/SanginiServicesPage.scenes';
import BuyJewelleriesPage from './scenes/BuyJewelleriesPage.scenes';
import UserProfilePage from './scenes/UserProfilePage.scenes';
import SignUpPage from './scenes/SignUpPage.scenes';
import PrivacyPolicy from "./scenes/PrivacyPolicy.scenes";
import { ForgotPasswordPage } from "./scenes/forgotPassword.scenes";
import { AuthGuard } from "./guards/authGuard";
import commonCss, { mobileView } from "./commonCss/commonCss";
import { StyleSheet, css } from "aphrodite";
import TermsAndConditions from "./scenes/TermsAndConditon.scenes";
import { AdminGuard } from "./guards/adminGuard";
import { AdminPage } from "./scenes/AdminPage.scenes";
import {EditProducts }from "./components/admin/products/editProduct.component";
import {ProductDetail }from "./components/admin/products/productDetails.component";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Navbar defaultAction="Buy Jewelleries">
          <Navbar.Actions label='Home'/>
          <Navbar.Actions label='Buy Jewelleries'/>
          <Navbar.Actions label='Services'/>
          <Navbar.Actions label='Contact Us'/>
          <Navbar.Actions label='QR Scan'/>
          <AdminGuard><Navbar.Actions label='Admin' /></AdminGuard>
        </Navbar>
        <Routes>
          <Route path='/BuyJewelleries' element={<BuyJewelleriesPage />} />
          <Route path='/Home' element={<HomePage />} />
          <Route path='/login' element={<LoginPage shouldLogout={true} />} />
          <Route path='/contactUs' element={<ContactUsPage />} />
          <Route path='/QRScan' element={<QRScanPage />} />
          <Route path='/Services' element={<ServicesPage />} />
          <Route path='/profile' element={<AuthGuard><UserProfilePage/></AuthGuard>} />
          <Route path='/signup' element={<SignUpPage />} />
          <Route path='/forgotPassword' element={<ForgotPasswordPage />} />
          <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
          <Route path="/termsandcondition" element={<TermsAndConditions/>}/>
          <Route path='/Admin' element={<AdminGuard><AdminPage/></AdminGuard>} />

          <Route path='/customer' element={<AdminGuard><AdminPage/></AdminGuard>} />
          <Route path='/rate' element={<AdminGuard><AdminPage/></AdminGuard>} />
          <Route path='/billing' element={<AdminGuard><AdminPage/></AdminGuard>} />
          <Route path='/editcustomer' element={<AdminGuard><AdminPage/></AdminGuard>} />
          <Route path='/addProduct' element={<AdminGuard><AdminPage/></AdminGuard>} />
          <Route path='/displayProduct' element={<AdminGuard><AdminPage/></AdminGuard>} />
          <Route path='/editProduct' element={<EditProducts/>} />
          <Route path='/product/:id' element={<AdminGuard><ProductDetail/></AdminGuard>} />
          <Route path="/" element={<HomePage />} />
          
          
        </Routes>
        <footer>
        <div className={css(commonCss.flexRow, commonCss.alignItemsCenter, commonCss.justifySpaceBetween, styles.footer)}>
          <div>Copyright © 2022 Sangini Jewellers - All Rights Reserved.</div>
          <div className={css(styles.textRight)}>
            Sole Proprietorship
          </div>
        </div  >
          <div className={css(commonCss.flexRow, commonCss.alignItemsCenter, commonCss.justifySpaceBetween, styles.footercontent)}>
          <Link to="/privacyPolicy" className={css(styles.privacyLink)}>Privacy Policy</Link>
          <Link to="/termsandcondition" className={css(styles.privacyLink)}>Terms And Condition</Link>
          
          </div>
        </footer>
      </BrowserRouter>
    </Provider>
  );
}

const styles = StyleSheet.create({
  footer: {
    margin: "50px 10% 0 10%",
    padding: "25px 0 20px 0",
    [mobileView]: {
      margin: "10% 10px",
      fontSize: "0.8rem"
    },
    borderTop: "1px solid #ccc",
    color: "#666",
  },
  textRight: {
    textAlign: "right",
  },
  footercontent : {
    margin: "0px 10% 0 10%",
    padding: "25px 0 10px 0",
    [mobileView]: {
      margin: "10% 10px",
      fontSize: "0.8rem"
    },
    textDecoration: "none",

  },
  privacyLink: {
    textDecoration: "none", 
    color: "inherit", 
  },

});

export default App;
