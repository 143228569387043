import { useMemo } from "react";
import { css, StyleSheet } from "aphrodite";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { darkAmberColor, softBlack, white } from "../../../theme";
import { ProductsDTO } from "../../../models/products";
import {  useSelector } from "react-redux";
import { StoreSelector } from "../../../store/store";
import { GoldRateDTO } from "../../../models/goldRate";
import { getID } from "./editProduct.component";
import { useProductsHook } from "../../../hooks/useProductsHook";
import { ConfigProvider, Pagination } from "antd";
import { GoldRateBreakUp } from "../rateBreakUp/goldRateBreakUp"; // Import the hook
import commonCss, { rowsGap } from "../../../commonCss/commonCss";
import { Search } from "../../searchbars/searchBar.component";

export const DisplayProducts = () => {
  const navigate = useNavigate();
  const rate = useSelector((state: StoreSelector) => state.goldRate);
  const goldRate = GoldRateDTO.fromJSON(rate);
  const {
    deleteProductByIndex,
    currentPage,
    onChangeSearchValue,
    setCurrentPage,
    products,
  } = useProductsHook();

  return (
    <div className={css(commonCss.flexColumn, rowsGap("30px"))}>
      <Search onChangeSearchValue={onChangeSearchValue} />
      <div className={css(style.container)}>
        {currentPage.map((product, index) => {
          const priceBreakup = ProductsDTO.fromJSON(product).getPriceBreakup(goldRate)
          return (
            <div key={product.id} className={css(style.cardInner)} onClick={() => {
              navigate("/product/" + product.id);
            }}>
              <div className={css(style.imageCard)}>
                <img
                  className={css(style.image)}
                  src={product.image}
                  alt={` ${product.productName}`}
                />
              </div>
              <div className={css(style.contentCard)}>
                <ul className={css(style.list)}>
                  <li>{product.productTagNumber}</li>
                  <li>
                    <b>{product.productName}</b>
                  </li>
                  <li>&#8377;{priceBreakup.finalPrice}</li>

                </ul>
                <div className={css(style.buttonContainer)}>
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      padding: "0px",
                    }}
                    onClick={() => {
                      navigate("/editProduct/"+product.id);
                    }}
                  >
                    <FiEdit
                      className={css(style.button)}
                      color={darkAmberColor}
                      size="1.1rem"
                    />
                  </button>
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      padding: "0px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteProductByIndex(index);
                    }}
                  >
                    <MdDeleteOutline
                      className={css(style.button)}
                      color={darkAmberColor}
                      size="1.3rem"
                    />
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={css(style.pagination)}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: darkAmberColor,
              controlItemBgActive: white,
            },
          }}
        >
          <Pagination
            style={{ fontSize: "17px" }}
            total={products.length}
            showQuickJumper
            pageSize={30}
            showTotal={(total) => `Total  ${total}  products`}
            onChange={(page, pageSize) => {
              const startIndex = (page - 1) * pageSize;
              const endIndex = page * pageSize;
              if (page * 9 <= products.length) {
                setCurrentPage(products.slice(startIndex, endIndex));
              } else {
                setCurrentPage(products.slice(startIndex));
              }
            }}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

const style = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "0 1rem",
    maxWidth: "1500px",
    margin: "0 auto",
    flexWrap: "wrap",
    gap: '1rem',
  },
  cardInner: {
    // flex: "1 0 20%",
    // position: "relative",
    width: '15rem',
    height: "25rem",
    backgroundColor: "#fff",
    margin: "0.5rem",
    boxShadow: "0 1rem 3rem rgba(0,0,0,0.2)",
    borderRadius: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  imageCard: {
    width: "12rem",
    height: "12rem",
    borderRadius: "5%",
    overflow: "hidden",
    border: "8px solid rgba(0, 0, 0, 0.2)",
  },
  image: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
  contentCard: {
    textAlign: "left",
  },
  list: {
    textDecoration: "none",
    listStyle: "none",
    fontSize: "1.2rem",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center", // Center buttons horizontally
    alignItems: "center", // Center buttons vertically
    width: "100%", // Make the container take the full width of its parent
  },
  button: {
    backgroundColor: "white",
    transitionDuration: "0.3s",
    padding: "0.2rem",
    ":hover": { color: softBlack },
    color: "darkAmberColor",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  pagination: {
    display: "flex",
    alignContent: "center",
    justifyContent: "space-around",
    padding: "7px",
  },
});
