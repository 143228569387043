import { DocumentData, orderBy, where } from "firebase/firestore";
import { Query } from "../library/query";


export class CustomersRepository {
    private static customersCollection = Query.getCollection('customers');     //replace customercopy with customers

    static async getAllCustomers(): Promise<Array<DocumentData>> {
        return await this.customersCollection.getAllDocs();

    }

    static async addCustomer(data:any){
        return await this.customersCollection.addNewDoc({...data});
    }

    static async editCustomer(id:string,data:any){
        return await this.customersCollection.updateDocById(id,{...data});
    }

    static async deleteCustomer(id:string){
        return await this.customersCollection.removeDocById(id);
    }

    static async searchCustomer(value:string){
        let whereConditions = [];
        if (value as any - 0 > 0) {
            whereConditions = [where('contact', '>=', value)];
        } else {
            whereConditions = [orderBy('name'), where('name', '>=', value)];
        }
        return await this.customersCollection.searchDoc(whereConditions);

    }

    static async pushToWaHook(){
        const customers = await this.getAllCustomers();
        const aa: any = [];
        customers.forEach(customer => {
            const payload = customer?.toAddToWANNotifier();
            if (payload)
                aa.push(payload)
            // axios.post("https://app.wanotifier.com/api/v1/contacts/?key=Hq6D5q92BzzYD6zzU8qG8vP6Rowj4W", payload, {
            //     "Content-Type": "application/json",
            //     "Access-Control-Allow-Origin": "*"
            // } as any)
        })
    }

    static async pushToVcard(){
        const customers = await this.getAllCustomers();
        let aa= "";
        customers.forEach(customer => {
            const payload = customer?.toVCARD();
            if (payload)
                aa = aa + "\n"+ payload
            // axios.post("https://app.wanotifier.com/api/v1/contacts/?key=Hq6D5q92BzzYD6zzU8qG8vP6Rowj4W", payload, {
            //     "Content-Type": "application/json",
            //     "Access-Control-Allow-Origin": "*"
            // } as any)
        })
    }
}