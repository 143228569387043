import { useRef, useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomersRepository } from "../repositories/customerRepository";
import { CustomerStateData, setCustomers, deleteCustomer } from "../store/customersSlice";
import { StoreSelector } from "../store/store";



const getDebounce = (callbackFunction: Function) => 
    setTimeout(() => {
        callbackFunction()
    }, 800);

let timer:any = null;


export const useCustomersHook = () => {
       
    const customers = useSelector((state: StoreSelector) => state.customers);
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState([] as CustomerStateData[]);

    const searchCustomer = (searchedValue: string)=>{
        CustomersRepository.searchCustomer(searchedValue).then((_customers: any) => {
            dispatch(setCustomers(_customers));
            _customers.length<=10
                ? setCurrentPage(_customers)
                : setCurrentPage(_customers.slice(0,10));
        });
    }


    const showAllCustomers = useCallback(()=>{
        CustomersRepository.getAllCustomers().then(_customers => {
            const customers = _customers as CustomerStateData[]
            dispatch(setCustomers(customers))
            if(customers.length<=10){
                setCurrentPage(customers)
            }else{
                setCurrentPage(customers.slice(0,10))
            }
        });

    }, []);

        
    function onChangeSearchValue(e: any) {
        const searchedValue = e.target.value;
        if (timer) {
            clearTimeout(timer)
        }
        if(searchedValue!==''){    
            timer = getDebounce(() => searchCustomer(searchedValue))
        }else{
            timer = getDebounce(showAllCustomers)
        }
    }

    function deleteCustomerByIndex(index: number) {
        const confirmed = window.confirm("Are you sure?")
        if(confirmed)
            CustomersRepository.deleteCustomer(customers[index].id as string).then(() => dispatch(deleteCustomer(index)))
    }

    useEffect(()=> {showAllCustomers()}, []);

    // Synchronize currentPage state with customers state
    useEffect(() => {
        if (customers.length <= 10) {
            setCurrentPage(customers);
        } else {
            setCurrentPage(customers.slice(0, 10));
        }
    }, [customers]);

    return {customers, currentPage, onChangeSearchValue, deleteCustomerByIndex, setCurrentPage }
}