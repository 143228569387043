import { StyleSheet, css } from "aphrodite"
import React from "react"
import commonCss, {height as Height} from "../../commonCss/commonCss"
import { shadowColor } from "../../theme"
interface sectionBodyProps extends React.AllHTMLAttributes<HTMLElement>{}

export const TileCard = ({height, children}: sectionBodyProps) => {
    return <article className={css(commonCss.margin10, commonCss.padding10, styles.tile, Height(height as string || "200px") )}>
        {children}
    </article>
}

const styles = StyleSheet.create({
    tile: {
        boxShadow: `1px 1px 5px ${shadowColor}`,
        borderRadius: "10px",
        display: "inline-block",
        textAlign: "center",
        alignSelf: "center",
        fontSize: "1rem",
        fontWeight: "bold"
    }
})