import { css, StyleSheet } from "aphrodite";
import { useDropzone } from "react-dropzone";
import { FcOpenedFolder } from "react-icons/fc";
import { IoMdCloudUpload } from "react-icons/io";
import commonCss, { colsGap, rowsGap, padding, minWidth, maxWidth, margin } from "../../../commonCss/commonCss";
import { useProductActions } from "../../../hooks/useAddProductHook";
import { useRef } from "react";
import { ProductDetailsState } from "../../../store/productDetailsSlice";
import { setTag, TagState } from "../../../store/productTagSlice";
import { Select } from "antd";


export function ProductDescription({fileRef, productDetailsState, tagsState}:{fileRef:any, productDetailsState:ProductDetailsState, tagsState:TagState[]}){

    const {updateProductDetails, handleDrop, handleFileChange, setTagOnProduct} = useProductActions({productDetailsState, tagsState});
    const {preview, productDetails} = productDetailsState;
    const tagIds = tagsState.map((tag)=>({ value: tag.id}));
    function handleNameChange(e: any) {
        const searchedValue: string = e.target.value;
        const value = searchedValue.trim();
        if(value!==''){    
            const nameSplit:string[] = [];
            value.split(" ").forEach((value)=>{
                if(value!=''){
                    nameSplit.push(value[0].toUpperCase()+value.slice(1))
                }})
            const name = nameSplit.join(' ')
            updateProductDetails({name: 'productName', value: name})
        }else{
            updateProductDetails({name: 'productName', value: ''})
        }
    }
    
    function handleTagDropDownChange(value: string): void {
        const tagData = tagsState.filter(tag => tag.id === value)[0];
        if (tagData?.id){
            updateProductDetails({name: 'productName', value: tagData.name})
            updateProductDetails({name: 'category', value: tagData.category})
            updateProductDetails({name: 'goldKarat', value: tagData.karat})
            updateProductDetails({name: 'subCategory', value: tagData.subCategory})
            setTagOnProduct(value);
        }
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => handleDrop(fileRef, acceptedFiles),
        accept: { 'image/*': ['.jpeg', '.jpg','.png', '.gif'] },
    });
    

    return<>
    <div className={css(style.parent,commonCss.flexRow, commonCss.justifySpaceBetween, commonCss.flexWrap, colsGap('30px'), rowsGap('30px'))}>
        <div className={css(style.box1,padding('1.6rem'), minWidth('200px'),  maxWidth('800px'))}>

            <div className={css(style.row)}>
                <div className={css(style.inputBox)}>
                    <span className={css(style.span)}>Tag ID {productDetails.productTagNumber && `:  ${productDetails.productTagNumber}`}</span>
                    <div className={css(margin('10px 0 0 0'))}>
                        <Select
                            showSearch
                            style={{ width: 120 }}
                            allowClear
                            options={tagIds}
                            placeholder="Tag ID"
                            onSelect={handleTagDropDownChange}/>
                    </div>
                    
                </div> 
            </div>

            <div className={css(style.row)}>
                <div className={css(style.inputBox)}>
                    <span className={css(style.span)}>Name</span>
                    <input 
                    className={css(style.input)}
                    value={productDetails.productName}
                    type="text"
                    onChange={(e)=>{handleNameChange(e)}} />
                </div>
            </div>

            <div className={css(style.inputBox,style.discription)}>
                <span className={css(style.span)}>Description</span>
                <input 
                className={css(style.input,style.discriptionInput)}
                value={productDetails.description}
                type="text"
                onChange={(e)=>{updateProductDetails({name:"description", value:e.target.value})}} />
            </div>

            <div className={css(style.row)}>
                <div className={css(style.inputBox)}>
                    <span className={css(style.span)}>Making Charges (in %)</span>
                    <input className={css(style.input)} type="number" value={productDetails.makingCharges || '17'}
                    onChange={(e)=>{updateProductDetails({name:"makingCharges", value:e.target.value})}}/>
                </div>
                <div className={css(style.inputBox)}>
                    <span className={css(style.span)}>Time Taken For Making (in days)</span>
                    <input className={css(style.input)} type="number" value={productDetails.timeTakenForMaking || '15'}
                    onChange={(e)=>{updateProductDetails({name:"timeTakenForMaking", value:e.target.value})}}/>
                </div>
            </div>

            <div className={css(style.row)}>
                <div className={css(style.inputBox)}>
                    <span className={css(style.span)}>Category</span>
                    <input className={css(style.input)}  type="text" value={productDetails.category}
                    onChange={(e)=>{updateProductDetails({name:"category", value:e.target.value})}}/>
                </div>
                <div className={css(style.inputBox)}>
                    <span className={css(style.span)}>SubCategory</span>
                    <input className={css(style.input)}  type="text" value={productDetails.subCategory}
                    onChange={(e)=>{updateProductDetails({name:"subCategory", value:e.target.value})}}/>
                </div>
            </div>
        </div>

        <div className={css(style.box2,padding('1.6rem'), minWidth('200px'),  maxWidth('800px'))}>
            <span className={css(style.logoCircle)}>< FcOpenedFolder className={css(style.imageIcon)}/></span>
            <div className={css(style.imageBody)}> 
                <h2 className={css(style.imageTitle)}>Upload Image</h2>
                <div {...getRootProps({ className: css(style.imageButton) })}>
                    <input  {...getInputProps()} type="file" onChange={(e) => handleFileChange(e, fileRef)} accept="image/*" capture="environment" />
                    {!preview && !isDragActive &&(
                        <>
                            <IoMdCloudUpload className={css(style.imageIcon)} />
                            <span className={css(style.uploadTitle)}>Drag file here to upload</span>
                            <span className={css(style.uploadDescription)}>
                                Alternatively you can select a file by 
                                <br />
                                <strong className={css(style.strong)}>
                                    Clicking here
                                </strong>
                            </span>
                        </>
                    )}
                    {isDragActive && (<p className={css(style.dragActive)}>Drop the file here...</p>)}
                    {preview && (
                        <img src={preview} alt="Preview" className={css(style.imagePreview)} />
                    )}
                </div>
            </div>
        </div>
    </div>

    </>
}

const style = StyleSheet.create({
    parent:{
        display:'flex',
        // gridTemplateColumns:'1fr 1fr',
        flexWrap:'wrap',
        columnGap:'10px',
    },
    heading:{
        fontWeight:'bolder',
        fontSize:'20px'
    },
    box1:{
        // margin:'1.5rem',
        padding:'30px',
        boxShadow: '0px 1px 5px #aaa',
        fontSize:'18px',
        flex:1,
    },
    row:{
        display:'flex',
        flexWrap: 'wrap',
        justifyContent:'space-between',
        alignItems:'center',
    },
    inputBox:{
        display:'flex',
        flexDirection:'column',
        width:'47%',
        // height:'50px',
        marginBottom:'20px',
        },
    input:{
        position:'relative',
        width:'auto',
        // border:'1px solid #aaa',
        outline:'none',
        background:'none',
        padding:'10px',
        borderRadius:'10px',
        fontSize:'18px',
        marginTop:'10px',
        // boxShadow:'0px 1px 5px #aaa',
        // margin: '5px',
        // padding:'10px',
        // width: 'auto',
        color:'grey',
        border:'none',
        boxShadow: '0px 0px 3px #D9D9D9',
        ':focus':{outline:'none',boxShadow:'0px 0px 3px #FFCC01'},

    },
    span:{
        
        fontSize:'18px',
    },
    discription:{
        width:'100%',
    },
    discriptionInput:{
        height:'80px',
    },
    box2:
    {
        padding:'30px',
        boxShadow: '0px 1px 5px #aaa',
        fontSize:'18px',
        paddingBottom:'0px',
        flex:1,
    },
    logoCircle:{
        width: '3.5rem',
        height: '3.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor:'#eee',
       
    },
    imageBody:{
        padding:'1rem 1.5rem',
    },
    imageTitle:{
        fontWeight:'bold'
    },
    imageDescription:{
        color:'#aaa'
    },
    imageButton:{
        margin:'1rem',
        border:'1px dashed #aaa',
        backgroundColor:'transparent',
        padding:'1.5rem',
        width:'80%',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        textAlign:'center',
        ':hover':{
            border:'1px dashed #f0bf4c'
        },
        ':focus':{
            border:'1px dashed #f0bf4c',
        },
       
    },
    imageIcon:{
        display:'block',
        width:'2.25rem',
        height:'2.25rem'
    },
    uploadTitle:{
        marginTop:'1rem',
        display:'block',
        fontWeight:'bolder',
        color:'#000'
    },
    uploadDescription:{
        display:'block',
        color:'#aaa',
    },
    imagePreview: {
        marginTop: '20px',
        maxWidth: '100%',
        height: 'auto',
        borderRadius: '4px',
    },  
    dragActive: {
        border: '2px solid #f0bf4c',
        backgroundColor: '#f0bf4c',
    },
    strong:{
        color:'#f0bf4c',
        fontWeight:'bold'
    },
    buttonBody:{
            padding: '1rem 1.5rem 1.5rem',
            display: 'flex',
            justifyContent:'flex-end',           
    },
    button:{
        padding:'.5rem 1rem',
        fontWeight: 'bold',
        border: '2px solid #aaa',
        borderRadius: '.25rem',
        marginLeft: '.75rem',
        boxShadow:'0px 1px 5px #aaa'
    },
    cancelButton:{
        backgroundColor:'transparent'
    },
    uploadButton:{
        backgroundColor:'#f0bf4c',
        marginRight: '-1.5rem',
    },
    Quality:{
        ':first-of-type':{
        display:'flex',
        alignItems:'flex-start',
        marginBottom:'5px',
        marginTop:'15px'
    }
        
    },
   qualityDiv:{
    marginRight:'10px'
   },
   radioInput:{
    appearance:'none',
    borderRadius:'50%',
    width:'16px',
    height:'16px',
    border:'2px solid #aaa',
    marginRight:'5px',
    position:'relative',            
    boxShadow:'0px 1px 5px #aaa',
    ':checked':{
            border: '6px solid black',   
    }
   },
   label:{
    marginRight:'20px',
    lineHeight:'32px',
   },
   buttonDiv:{
    marginTop:'30px',
    display:'flex',
    justifyContent:'center',
   
   },
   SubmitButton:{
    alignItems:'center',  
    padding:'10px',
    fontSize:'18px',
    outline:'none',
    border:'none',
    borderRadius:'10px',
    width:'200px',
    boxShadow:'0px 1px 10px #BF9850',
    backgroundColor:'#f0bf4c'
   },
   availability:{
    width:'100%'
   }
})
