import { StyleSheet,css } from "aphrodite";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FcOpenedFolder } from "react-icons/fc";
import { IoMdCloudUpload } from "react-icons/io";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { fireStorage } from "../../../config/firestore";
import {v4 } from 'uuid';


interface ImageProps {
    onImageUpload: (imageUrl: string) => void;
    existingImage?: string; 
    }
export default function Image({onImageUpload,existingImage}:ImageProps){
    const [file, setFile] = useState<File | null>(null);
    const [preview, setPreview] = useState<string | null>(existingImage || null);
    const [uploading, setUploading] = useState(false);
    const [downloadURL, setDownloadURL] = useState<string | null>(null);
   // const [error, setError] = useState<string | null>(null);

   useEffect(() => {
    if (existingImage) {
        setPreview(existingImage);
    }
}, [existingImage]);
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0])
         {
            setFile(e.target.files[0]);
            setPreview(URL.createObjectURL(e.target.files[0]));
          }
          
        };
        const handleDrop = useCallback((acceptedFiles: File[]) => {
            if (acceptedFiles.length) {
               const file = acceptedFiles[0];
                setFile(file);
                setPreview(URL.createObjectURL(file));
            }
        }, []);
    
        const { getRootProps, getInputProps, isDragActive } = useDropzone({
            onDrop: handleDrop,
            accept: { 'image/*': ['.jpeg', '.jpg','.png', '.gif'] },
            // maxSize: 5242880, // 5MB file size limit
            // onDropRejected: () => setError("File size must be under 5MB."),
        });
        const uploadImageToFirebase = async () => {
            if(file){
                setUploading(true);
                const storageRef = ref(fireStorage, `products/${v4()}_${file.name}`);
                await uploadBytes(storageRef, file);
                // const url = await getDownloadURL(storageRef);
                //setDownloadURL(url);
                // setUploading(false);
                const url = await getDownloadURL(storageRef);
                setUploading(false);
                setPreview(null); // Clear preview after upload
                onImageUpload(url); // Pass the image URL to the parent component
                alert('Image Uploaded successfully');
            }
            
        };
        return(
            <>
                 <span className={css(style.logoCircle)}>< FcOpenedFolder className={css(style.imageIcon)}/></span>
                        <div className={css(style.imageBody)}> 
                         <h2 className={css(style.imageTitle)}>Upload Image</h2>
                         <p className={css(style.imageDescription)}>Attach the file below</p>
                         <div {...getRootProps({ className: css(style.imageButton) })}>
                                <input  {...getInputProps()} type="file" onChange={handleFileChange} />
                                  {!preview && !isDragActive &&(
                                    <>
                                        <IoMdCloudUpload className={css(style.imageIcon)} />
                                        <span className={css(style.uploadTitle)}>Drag file here to upload</span>
                                        <span className={css(style.uploadDescription)}>Alternatively you can select a file by <br /><strong className={css(style.strong)}>Click here</strong></span>
                                    </>
                                )}
                                {isDragActive && (<p className={css(style.dragActive)}>Drop the file here...</p>)}
                                {preview && (
                                    <img src={preview} alt="Preview" className={css(style.imagePreview)} />
                                )}
                            </div>
                        </div>
                        <div className={css(style.buttonBody)}>
                            {file && (
                                <button className={css(style.button, style.uploadButton)} onClick={uploadImageToFirebase} disabled={uploading}>
                                    {uploading ? 'Uploading...' : 'Upload'}
                                </button>
                            )}
                      </div>
            </>
        )
}
const style = StyleSheet.create({
    logoCircle:{
        width: '3.5rem',
        height: '3.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor:'#eee',
       
    },
    imageBody:{
        padding:'1rem 1.5rem',
    },
    imageTitle:{
        fontWeight:'bold'
    },
    imageDescription:{
        color:'#aaa'
    },
    imageButton:{
        marginTop:'1.25rem',
        border:'1px dashed #aaa',
        backgroundColor:'transparent',
        padding:'3rem',
        width:'80%',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        textAlign:'center',
        ':hover':{
            border:'1px dashed #f0bf4c'
        },
        ':focus':{
            border:'1px dashed #f0bf4c',
        },
       
    },
    imageIcon:{
        display:'block',
        width:'2.25rem',
        height:'2.25rem'
    },
    uploadTitle:{
        marginTop:'1rem',
        display:'block',
        fontWeight:'bolder',
        color:'#000'
    },
    uploadDescription:{
        display:'block',
        color:'#aaa',
    },
    imagePreview: {
        marginTop: '20px',
        maxWidth: '100%',
        height: 'auto',
        borderRadius: '4px',
    },  
    dragActive: {
        border: '2px solid #f0bf4c',
        backgroundColor: '#f0bf4c',
    },
    strong:{
        color:'#f0bf4c',
        fontWeight:'bold'
    },
    buttonBody:{
        padding: '1rem 1.5rem 1.5rem',
        display: 'flex',
        justifyContent:'flex-end',           
},
button:{
    padding:'.5rem 1rem',
    fontWeight: 'bold',
    border: '2px solid #aaa',
    borderRadius: '.25rem',
    //marginLeft: '.50rem',
    boxShadow:'0px 1px 5px #aaa'
},
cancelButton:{
    backgroundColor:'transparent'
},
uploadButton:{
    backgroundColor:'#f0bf4c',
    marginRight: '2.5rem',
},
})