import { DiamondRateDTO } from "../models/diamondRate";
import { Query } from "../library/query";
import { orderBy, limit } from "firebase/firestore";

export const DiamondTypes = {
    si_GH: 'si_GH',
    si_IJ: 'si_IJ',
    si_JK: 'si_JK',
    si_i_FG: 'si_i_FG',
    vs_si_FG: 'vs_si_FG',
    vs_si_GH: 'vs_si_GH',
    vs_si_HI: 'vs_si_HI',
    vs_si_IJ: 'vs_si_IJ',
    vvs_EF: 'vvs_EF',
    vvs_vs_FG: 'vvs_vs_FG',
    vvs_vs_GH: 'vvs_vs_GH',
    vvs_vs_HI: 'vvs_vs_HI'
}

export class DiamondRateRepository{
    private static diamondRateCollection = Query.getCollection('diamondrates');

    // static async todaysRate(): Promise<DiamondRateDTO>{
    //     const rateData:any = await this.diamondRateCollection.findOne(orderBy('timestamp', 'desc'), limit(1));
    //     return DiamondRateDTO.fromJSON(rateData)
    // }

    static async addRate(data: Record<string, any>,customID: string | undefined){
        await this.diamondRateCollection.addNewDoc(data,customID)
    }

    static async rateByType(type: keyof typeof DiamondTypes){
        const rateData = await this.diamondRateCollection.findById(type)
        return DiamondRateDTO.fromJSON(rateData).rate
    }
}