import { useMemo } from "react";
import { StyleSheet, css } from "aphrodite";
import { FaStar } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { IoReturnUpBack } from "react-icons/io5";
import { GiReturnArrow } from "react-icons/gi";
import { MdOutlineRepeatOne } from "react-icons/md";
import { GiGoldStack } from "react-icons/gi";
import { IoPricetagsSharp } from "react-icons/io5";
import { IoDiamond } from "react-icons/io5";
import { darkAmberColor } from "../../../theme";
import { useProductHook } from "../../../hooks/useProductHook";
import { ProductsDTO } from "../../../models/products";



export function ProductDetail() {

  const {product, goldRate} = useProductHook();
  const {totalGoldCost, makingCharges, finalPrice} = useMemo(() => ProductsDTO.fromJSON(product.productDetails).getPriceBreakup(goldRate), [product.productDetails.id]);

  return (
    <>
      <div className={css(style.container)}>
        <div className={css(style.centerSection)}>
          <div className={css(style.mainImageContainer)}>
            <img
              src={product.productDetails.image}
              alt={product.productDetails.productName}
              className={css(style.mainImage)}
            />
          </div>
          <div className={css(style.leftSection)}>
            <div className={css(style.thumbnail)}>
              <img
                src={product.productDetails.image}
                alt={product.productDetails.productName}
                className={css(style.thumbnailImage)}
              />
            </div>
            <div className={css(style.thumbnail)}>
              <img
                src={product.productDetails.image}
                alt={product.productDetails.productName}
                className={css(style.thumbnailImage)}
              />
            </div>
            <div className={css(style.thumbnail)}>
              <img
                src={product.productDetails.image}
                alt={product.productDetails.productName}
                className={css(style.thumbnailImage)}
              />
            </div>
          </div>
        </div>
        <div className={css(style.rightSection)}>
          <div className={css(style.rating)}>
            <div className={css(style.numberRating)}>
              <span className={css(style.span)}>4.9</span>
              <span className={css(style.span)}>
                <FaStar />
              </span>
            </div>
            <span className={css(style.partition)}></span>
            <div className={css(style.numberRating)}>
              <span className={css(style.span)}>30</span>
              <span className={css(style.span)}>Rating</span>
            </div>
          </div>
          <h1 className={css(style.productName)}>{product.productDetails.productName}</h1>
          <div className={css(style.priceSection)}>
            <h2 className={css(style.price)}>₹{finalPrice}</h2>
            {/* <p className={css(style.discount)}>
              Price: ₹ {finalPrice}
            </p> */}
            {/* <p className={css(style.offer)}>Offer: {product.productDetails.goldWeight}</p> */}
          </div>
          <div className={css(style.customise)}>
            <div className={css(style.customiseSection)}>
              <span className={css(style.label)}>Size</span>
              <span className={css(style.value)}>10(48mm)</span>
            </div>

            <div className={css(style.customiseSection)}>
              <span className={css(style.label)}>Gold</span>
              <span className={css(style.value)}>
                {product.productDetails.goldKarat}
              </span>
            </div>

            <div className={css(style.customiseSection)}>
              <span className={css(style.label)}>Diamond</span>
              <span className={css(style.value)}>
                {product.productDetails.diamondClarity} {product.productDetails.diamondColor} 
              </span>
            </div>

            <div className={css(style.customiseButtonSection)}>
              <span className={css(style.customiseButton)}>CUSTOMISE</span>
            </div>
          </div>
          <div className={css(style.location)}>
            <input
              type="text"
              className={css(style.locationInput)}
              placeholder="Enter pincode  "
            />
          </div>
          <div className={css(style.actions)}>
            <button className={css(style.addToCartButton)}>Add to Cart</button>
            <button className={css(style.buyNowButton)}>Buy Now</button>
          </div>

          <div className={css(style.productInfo)}>
            <h1 className={css(style.heading)}> Product Details </h1>
            <p className={css(style.description)}>{product.productDetails.description}</p>
          </div>

          <div className={css(style.goldDetails)}>
            <div className={css(style.goldIconText)}>
              <span className={css(style.goldIcon)}>
                <GiGoldStack color={darkAmberColor} />
              </span>
              <h3 className={css(style.iText)}>GOLD</h3>
            </div>
            <div className={css(style.goldInformation)}>
              <div>
                <h4>Dimensions</h4>
                <p>
                  <span>width : N/A</span>
                </p>
                <p>
                  <span>height : N/A</span>
                </p>
              </div>
              <div>
                <h3>Weight</h3>
                <p>
                  <span>Gross : {product.productDetails.goldWeight}</span>
                </p>
              </div>
              <div>
                <h3>Purity</h3>
                <p>
                  <span>{product.productDetails.goldKarat}</span>
                </p>
              </div>
            </div>
          </div>
          {
            product.productDetails.diamondWeight ?
            <div className={css(style.goldDetails)}>
            <div className={css(style.goldIconText)}>
              <span className={css(style.goldIcon)}>
                <IoDiamond color={darkAmberColor} />
              </span>
              <h3 className={css(style.iText)}>DIAMOND</h3>
            </div>
            <div className={css(style.goldInformation)}>
              <div>
                <h4>Color</h4>
                <p>
                  <span>{product.productDetails.diamondColor}</span>
                </p>
              </div>
              <div>
                <h4>Clarity</h4>
                <p>
                  <span>{product.productDetails.diamondClarity} {product.productDetails.diamondColor}</span>
                </p>
              </div>
              <div>
                <h3>Cutting</h3>
                <p>
                  <span>Hearts & arrow</span>
                </p>
                <p>
                  <span>Total Pieces : {product.productDetails.diamondPieces}</span>
                </p>
              </div>
              <div>
                <h3>Carat</h3>
                <p>
                  <span>{product.productDetails.diamondWeight}</span>
                </p>
              </div>
            </div>
          </div> : ""
          }
          <div className={css(style.goldDetails)}>
            <div className={css(style.goldIconText)}>
              <span className={css(style.goldIcon)}>
                <IoPricetagsSharp color={darkAmberColor} />
              </span>
              <h3 className={css(style.iText)}>PRICE BREAKUP</h3>
            </div>
            <div className={css(style.goldInformation)}>
              {/* {goldPrices.map(({goldPricePerGram,makingCharges},index) => ( */}
              <div>
                <h4>Gold Cost</h4>
                <p>
                  <span>₹{totalGoldCost}</span>
                </p>
                <p>
                  <span>
                    makingChrgs:{makingCharges}
                  </span>
                </p>
              </div>
              <div>
                <h3>(Diamond/Stone) Cost</h3>
                <p>
                  <span>{}</span>
                </p>
              </div>
            </div>
          </div>
          <div className={css(style.iconContainer)}>
            <div className={css(style.iconSection)}>
              <div className={css(style.icon)}>
                <span>
                  <TiTick color={darkAmberColor} size="2.5rem" />
                </span>
              </div>
              <div className={css(style.iconText)}>
                <span>100%</span>
                <span>Certified</span>
              </div>
            </div>
            <div className={css(style.iconSection)}>
              <div className={css(style.icon)}>
                <span>
                  <IoReturnUpBack color={darkAmberColor} size="2.5rem" />
                </span>
              </div>
              <div className={css(style.iconText)}>
                <span>15 Days</span>
                <span>Money-Back</span>
              </div>
            </div>
            <div className={css(style.iconSection)}>
              <div className={css(style.icon)}>
                <span>
                  <GiReturnArrow color={darkAmberColor} size="2.5rem" />
                </span>
              </div>
              <div className={css(style.iconText)}>
                <span>Lifetime</span>
                <span>Exchange</span>
              </div>
            </div>
            <div className={css(style.iconSection)}>
              <div className={css(style.icon)}>
                <span>
                  <MdOutlineRepeatOne color={darkAmberColor} size="2.5rem" />
                </span>
              </div>
              <div className={css(style.iconText)}>
                <span>One Year</span>
                <span>Warranty</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const style = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    padding: "20px",
    margin: "100px",
    justifyContent: "center",
  },
  leftSection: {
    display: "flex",
    flexDirection: "row",
    marginRight: "20px",
  },
  thumbnail: {
    marginBottom: "10px",
  },
  thumbnailImage: {
    width: "80px",
    height: "80px",
    objectFit: "cover",
    borderRadius: "5px",
    border: "1px solid #ddd",
  },
  centerSection: {
    flex: 1,
    textAlign: "center",
    width: "1000px",
    display: "flex",
    flexDirection: "column",
    // justifyContent:'center',
    // alignItems:'center',
  },
  mainImageContainer: {
    width: "50vw",
    height: "500px",
    marginBottom: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "10px",
  },
  productName: {
    margin: "10px",
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  productSpecs: {
    fontSize: "16px",
    color: "#666",
  },
  rightSection: {
    //boxShadow: "0 2rem 4.5rem rgba(0,0,0,0.3)",
    display: "flex",
    flexDirection: "column",
    marginLeft: "20px",
    width: "50vw",
  },
  rating: {
    padding: "5px",
    margin: "10px",
    display: "flex",
    flexDirection: "row",
    width: "150px",
    height: "30px",
    borderRadius: "20px",
    border: "2px solid #ffcc01",
    justifyContent: "space-around",
    alignItems: "center",
  },
  numberRating: {
    display: "flex",
    flexDirection: "row",
    padding: "2px",
  },

  span: {
    marginRight: "5px",
    marginLeft: "5px",
  },
  partition: {
    border: "1px solid #ffcc01",
    height: "30px",
    backgroundColor: "#ffcc01",
  },

  priceSection: {
    marginLeft: "5px",
    marginBottom: "20px",
  },
  price: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#b12704",
  },
  discount: {
    margin: "10px",
    fontSize: "16px",
    textDecoration: "line-through",
    color: "#999",
  },
  offer: {
    margin: "10px",
    fontSize: "14px",
    color: "#b12704",
  },
  customise: {
    margin: "10px",
    display: "flex",
    height: "80px",
    borderRadius: "20px",
    border: "2px solid #ffcc01",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  customiseSection: {
    display: "flex",
    flex: "1",
    padding: "10px 20 px",
    borderRight: "1px solid #ffcc01",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  customiseButtonSection: {
    border: "none",
    background: "#ffcc01",
    padding: "10px 20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontWeight: "bold",
  },
  label: {
    fontWeight: "bold",
  },
  value: {},

  customiseButton: {},

  actions: {
    display: "flex",
    flexDirection: "row", // if you want the buttons stacked vertically
    alignItems: "center",
    justifyContent: "center",
    width: "100%", // adjust width as needed
  },
  addToCartButton: {
    display: "flex",
    margin: "10px",
    width: "150px",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    backgroundColor: "#aaa",
    color: "white",
    border: "none",
    borderRadius: "20px",
    cursor: "pointer",
    marginBottom: "10px",
    fontSize: "20px",
  },
  buyNowButton: {
    margin: "10px",
    width: "150px",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    backgroundColor: "#eee",
    border: "none",
    borderRadius: "20px",
    cursor: "pointer",
    fontSize: "20px",
  },
  location: {
    margin: "10px",
    marginTop: "40px",
    display: "flex",
    height: "50px",
    borderRadius: "10px",
    border: "2px solid #ffcc01",
    justifyContent: "space-around",
    overflow: "hidden",
    alignItems: "center",
    padding: "0 15px",
  },
  locationInput: {
    flexGrow: 1,
    height: "100%",
    border: "none",
    outline: "none",
    padding: "0 10px",
    fontSize: "20px",
    borderRadius: "10px",
  },
  iconContainer: {
    marginTop: "40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",

    // marginRight: "30px",
    alignItems: "center",
  },
  iconSection: {
    textAlign: "center",
  },
  icon: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    borderTop: "3px solid #aaa", // Add top border
    borderBottom: "3px solid #aaa",
    // boxShadow: "0.2rem 3.5rem #f9dc5c",

    // border:'3px solid #eee',
    //backgroundColor: "#f9dc5c",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px",
    fontSize: "40px",
    fontWeight: "bolder",
    ":hover": {
      borderLeft: "3px solid #aaa",
      borderRight: "3px solid #aaa",
      cursor: "pointer",
    },
  },
  iconText: {
    display: "flex",
    flexDirection: "column",
    marginTop: "15px",
  },

  productInfo: {
    margin: "10px",

    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "center",
  },
  heading: {
    margin: "10px",

    //alignItems: "center",
    fontFamily: "sen-serif",
  },
  description: {
    margin: "10px",

    marginTop: "5px",
    alignItems: "center",
    fontFamily: "sen-serif",
  },
  priceDetails: {
    display: "flex",
    flexDirection: "row",
    margin: "100px",
  },
  productSummary: {
    backgroundColor: "#f9f9f9",
    width: "15%",
    padding: "15px",
    borderRadius: "8px",
    marginBottom: "20px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    ":hover": {
      backgroundColor: "#e0e0e0",
    },
  },
  note: {
    color: "#999",
    fontSize: "12px",
    marginTop: "10px",
  },
  productPriceDetails: {
    margin: "10px",
    marginLeft: "20px",
    width: "85%",
  },
  goldDetails: {
    margin: "10px",

    display: "flex",
    //alignItems:'flex-start',
    flexDirection: "column",
    borderRadius: "15px",
    marginBottom: "15px",
    padding: "10px",
  },
  goldIconText: {
    display: "flex",
    alignItems: "center",
    // border:'4px solid #f9dc5c',
    // backgroundColor:'#f9dc5c',
    borderRadius: "10px",
    // padding:'10px 15px',
    marginBottom: "5px",
  },

  goldIcon: {
    marginLeft: "10px",
    fontSize: "25px",
    color: "#ffcc01",
    marginRight: "10px",
  },
  iText: {
    fontSize: "20px",
    margin: 0,
  },
  goldInformation: {
    display: "flex",
    // flexDirection:'row',
    backgroundColor: "#fcefb4",
    justifyContent: "space-around",
    borderRadius: "10px",
    padding: "15px",
  },

  // panel: {
  //   backgroundColor: "#f5f5f5",
  //   padding: "15px",
  //   borderRadius: "8px",
  //   marginBottom: "10px",
  //   cursor: "pointer",
  //   ":hover": {
  //     backgroundColor: "#e0e0e0",
  //   },
  // },
});
