import { useEffect } from "react";
import { RateRepository } from "../repositories/goldRateRepository";

interface goldItem {
    productName:string,
    productTagNumber:string,
    goldKarat:string,
    goldWeight:number,
    makingCharges:number
}

interface diamondItems {
    productName:string,
    productTagNumber:string,
    diamondPieces:number,
    diamondSize:string,
    makingCharges:number,
    diamondWeight:number
}
let goldItems : goldItem[];
let diamondItems : diamondItems[];

export const useBillingHook = () => {

    let customerDetails:{name:string,contact:string,address:string,docId:string} | undefined;

    
    let bill={
            igstGold : 0,
            cgstGold : 0,
            sgstGold : 0,
            totalBasePriceGold : 0,
            totalMakingChargesGold : 0,
            igstDiamond: 0, 
            cgstDiamond: 0, 
            sgstDiamond: 0, 
            totalBasePriceDiamond: 0, 
            totalMakingChargesDiamond: 0,
        };

    function enterGoldItems(items: goldItem[]){
        goldItems = items
    }
    
    function enterDiamondItems(items: diamondItems[]){
        diamondItems = items
    }

    function enterCustomerDetails(details: { name: string; contact: string; address: string; docId: string; } | undefined){
        customerDetails = details
    }

    async function goldItemsAmount(){

        const rates = await RateRepository.todaysRate().then((data)=>({
                '18K':data.rateOf18K,
                '22K':data.rateOf22K,
                '24K':data.rateOf24K
            }));

        goldItems.forEach((item) => {
            const makingChargePercent = item.makingCharges;
            const itemQuality = item.goldKarat;

            const karatRate = (itemQuality==='18K'?rates["18K"]:itemQuality==='22K'?rates["22K"]:rates["24K"])
            const basePrice = item.goldWeight*karatRate ? item.goldWeight*karatRate : 0 ;
            const makingCharge = basePrice*(makingChargePercent/100) ? basePrice*(makingChargePercent/100) : 0 ;

            bill.totalBasePriceGold +=basePrice;
            bill.totalMakingChargesGold+=makingCharge;
        })

        bill.igstGold = bill.totalBasePriceGold*0.03 || 0 ;
        bill.cgstGold = bill.sgstGold = bill.totalBasePriceGold*0.015 || 0;

        // return ({igst,cgst,sgst,totalBasePrice,totalMakingCharges})
    }

    const goldPreview = () =>{

    }

    const diamondItemsAmount = () =>{

    }

    useEffect(() =>{
        goldItemsAmount()
        diamondItemsAmount()

    },[goldItems,diamondItems,customerDetails])





    return {enterGoldItems,enterDiamondItems,enterCustomerDetails,bill}

}

