import { createSlice } from "@reduxjs/toolkit";
import { ProductStateData } from "./productSlice";

export type ProductDetailsState  = {
    productDetails: ProductStateData,
    preview: string | null,
}

const initialState: ProductDetailsState = {
  productDetails: {
    productTagNumber: "",
    productName: "",
    description: "",
    makingCharges: 15,
    timeTakenForMaking: 15,
    category: "",
    subCategory: "",
    image: "",
    goldWeight: 0,
    goldKarat: "18K",
    goldColor: "Yellow",
    goldWastage: 5,
    stoneType: "",
    stoneWeight: 0,
    stonePieces: 0,
    stoneCost: 0,
    diamondColor: "",
    diamondClarity: '',
    diamondWeight: 0,
    diamondSize: "",
    diamondPieces: 0,
    availableNow: false,
    availableOnOrder: false,
    availableOnEmi: false
  },
  preview: null,
}

const productDetailsSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
      setProductDetails(state: ProductDetailsState, action: { payload: Object }) {
        state.productDetails = {...state.productDetails, ...action.payload};
        return state;
      },
      setPreview(state: ProductDetailsState, action: { payload: string }){
        state.preview = action.payload;
        return state;
      },
      resetProduct() {
        return initialState;
      },
    },
});

export const {setProductDetails, setPreview, resetProduct} = productDetailsSlice.actions;
export default productDetailsSlice.reducer;