import { Button, Select } from "antd"
import { css, StyleSheet } from "aphrodite"
import { useProductActions } from "../../../hooks/useAddProductHook"
import { useState } from "react";
import { ProductDetailsState } from "../../../store/productDetailsSlice";
import { TagState } from "../../../store/productTagSlice";

export function AvailabilityDetails({productDetailsState, tagsState}:{productDetailsState:ProductDetailsState, tagsState:TagState[]}){

    const {updateProductDetails} = useProductActions({productDetailsState, tagsState});

    const availability = {
        AvailableNow:'availableNow',
        AvailableOnOrder:'availableOnOrder',
        AvailableOnEMI:'availableOnEmi'
    }

    const [availableNowState, setAvailableNowState] = useState(false)
    const [availableOnOrderState, setAvailableOnOrderState] = useState(false)
    const [availableOnEmiState, setAvailableOnEmiState] = useState(false)

    let newState: boolean

    function handleButtonState(data: string){
        switch (data){
            case availability.AvailableNow:
                newState = !availableNowState
                setAvailableNowState(newState)
                updateProductDetails({name: 'availableNow', value: newState})
                break
            case availability.AvailableOnOrder:
                newState = !availableOnOrderState
                setAvailableOnOrderState(newState)
                updateProductDetails({name: 'availableOnOrder', value: newState})
                break
            case availability.AvailableOnEMI:
                newState = !availableOnEmiState
                setAvailableOnEmiState(newState)
                updateProductDetails({name: 'availableOnEmi', value: newState})
                break
            
        }
    }


    
    
    return<>
    <h4 className={css(style.heading)}>Availability</h4>

    <div className={css(style.box1)}>
        <div className={css(style.row)}>
            {/* <Select
                // defaultValue="lucy"
                style={{ width: 200 }}
                allowClear
                options={availability}
                placeholder="Type"
                onSelect={(value)=>{updateProductDetails({name: value, value: true})}}/> */}
                <Button type={availableNowState?"primary":"default"} onClick={()=>{ handleButtonState(availability.AvailableNow) }}>Available Now</Button>
                <Button type={availableOnOrderState?"primary":"default"} onClick={()=>{ handleButtonState(availability.AvailableOnOrder) }}>Available On Order</Button>
                <Button type={availableOnEmiState?"primary":"default"} onClick={()=>{ handleButtonState(availability.AvailableOnEMI) }}>Available On EMI</Button>
        </div> 
    </div>    
    </>
}

const style = StyleSheet.create({
    parent:{
        display:'flex',
        // gridTemplateColumns:'1fr 1fr',
        flexWrap:'wrap',
        columnGap:'10px',
    },
    heading:{
        fontWeight:'bolder',
        fontSize:'20px'
    },
    box1:{
        // margin:'1.5rem',
        padding:'30px',
        boxShadow: '0px 1px 5px #aaa',
        fontSize:'18px',
        flex:1,
    },
    row:{
        display:'flex',
        flexWrap: 'wrap',
        justifyContent:'space-between',
        alignItems:'center',
    },
    inputBox:{
        display:'flex',
        flexDirection:'column',
        width:'47%',
        // height:'50px',
        marginBottom:'20px',
        },
    input:{
        position:'relative',
        width:'auto',
        // border:'1px solid #aaa',
        outline:'none',
        background:'none',
        padding:'10px',
        borderRadius:'10px',
        fontSize:'18px',
        marginTop:'10px',
        // boxShadow:'0px 1px 5px #aaa',
        // margin: '5px',
        // padding:'10px',
        // width: 'auto',
        color:'grey',
        border:'none',
        boxShadow: '0px 0px 3px #D9D9D9',
        ':focus':{outline:'none',boxShadow:'0px 0px 3px #FFCC01'},

    },
    span:{
        
        fontSize:'18px',
    },
    discription:{
        width:'100%',
    },
    discriptionInput:{
        height:'80px',
    },
    box2:
    {
        padding:'30px',
        boxShadow: '0px 1px 5px #aaa',
        fontSize:'18px',
        paddingBottom:'0px',
        flex:1,
    },
    logoCircle:{
        width: '3.5rem',
        height: '3.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor:'#eee',
       
    },
    imageBody:{
        padding:'1rem 1.5rem',
    },
    imageTitle:{
        fontWeight:'bold'
    },
    imageDescription:{
        color:'#aaa'
    },
    imageButton:{
        margin:'1rem',
        border:'1px dashed #aaa',
        backgroundColor:'transparent',
        padding:'1.5rem',
        width:'80%',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        textAlign:'center',
        ':hover':{
            border:'1px dashed #f0bf4c'
        },
        ':focus':{
            border:'1px dashed #f0bf4c',
        },
       
    },
    imageIcon:{
        display:'block',
        width:'2.25rem',
        height:'2.25rem'
    },
    uploadTitle:{
        marginTop:'1rem',
        display:'block',
        fontWeight:'bolder',
        color:'#000'
    },
    uploadDescription:{
        display:'block',
        color:'#aaa',
    },
    imagePreview: {
        marginTop: '20px',
        maxWidth: '100%',
        height: 'auto',
        borderRadius: '4px',
    },  
    dragActive: {
        border: '2px solid #f0bf4c',
        backgroundColor: '#f0bf4c',
    },
    strong:{
        color:'#f0bf4c',
        fontWeight:'bold'
    },
    buttonBody:{
            padding: '1rem 1.5rem 1.5rem',
            display: 'flex',
            justifyContent:'flex-end',           
    },
    button:{
        padding:'.5rem 1rem',
        fontWeight: 'bold',
        border: '2px solid #aaa',
        borderRadius: '.25rem',
        marginLeft: '.75rem',
        boxShadow:'0px 1px 5px #aaa'
    },
    cancelButton:{
        backgroundColor:'transparent'
    },
    uploadButton:{
        backgroundColor:'#f0bf4c',
        marginRight: '-1.5rem',
    },
    Quality:{
        ':first-of-type':{
        display:'flex',
        alignItems:'flex-start',
        marginBottom:'5px',
        marginTop:'15px'
    }
        
    },
   qualityDiv:{
    marginRight:'10px'
   },
   radioInput:{
    appearance:'none',
    borderRadius:'50%',
    width:'16px',
    height:'16px',
    border:'2px solid #aaa',
    marginRight:'5px',
    position:'relative',            
    boxShadow:'0px 1px 5px #aaa',
    ':checked':{
            border: '6px solid black',   
    }
   },
   label:{
    marginRight:'20px',
    lineHeight:'32px',
   },
   buttonDiv:{
    marginTop:'30px',
    display:'flex',
    justifyContent:'center',
   
   },
   SubmitButton:{
    alignItems:'center',  
    padding:'10px',
    fontSize:'18px',
    outline:'none',
    border:'none',
    borderRadius:'10px',
    width:'200px',
    boxShadow:'0px 1px 10px #BF9850',
    backgroundColor:'#f0bf4c'
   },
   availability:{
    width:'100%'
   }
})