import React from 'react';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import {ConfigProvider, Menu, theme} from 'antd';
import { useNavigate } from 'react-router-dom';
import { silverColor, darkAmberColor, white, softBlack } from '../../theme';
import { height, padding } from '../../commonCss/commonCss';
import { css , StyleSheet} from "aphrodite";

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
    
    {
      key: 'grp',
      type: 'group',
      children: [
        { key: 'rate', label: 'Rate' },
        { key: 'customer', label: 'Customer' },
        { key: 'billing', label: 'Billing' },
        { key:'addProduct',label:'Add Products' },
        { key:'displayProduct',label:'Display Products' }
      ],
    },
];

const SideBar: React.FC = () => {
    const navigate = useNavigate();

    const handleNavigation = (path:string) => {
        navigate(`/${path}`);
    };


    const onClick: MenuProps['onClick'] = (e) => {
        handleNavigation(e.key);

    };
    

    return (
        <ConfigProvider theme={{
            token:{
                colorPrimary: darkAmberColor,
                controlItemBgActive: white,
                colorBgContainer: '#eeeeee',
            }
        }}>
        <Menu
            onClick={onClick}
            mode="inline"
            items={items}
            className={css(styles.main)}
        />
        </ConfigProvider>
    );
};
  
const styles = StyleSheet.create({
    main: {
        color:softBlack,
        width: 'auto',
        fontSize:'15px',
        height:'250px',
        borderRadius:'20px',
        paddingLeft:'13px',
        paddingRight:'13px',
        fontWeight:'bold',
    },
})
export default SideBar;