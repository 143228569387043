import { createSlice } from '@reduxjs/toolkit';

export interface ProductStateData {
    id? : string,
    productTagNumber: string,
    productName: string,
    description: string,
    makingCharges: number,
    timeTakenForMaking: number,
    category: string,
    subCategory: string,
    image: string,
    goldWeight: number,
    goldKarat: string,
    goldColor: string,
    goldWastage: number,
    stoneType: string,
    stoneWeight: number,
    stonePieces: number,
    stoneCost: number,
    diamondClarity: string,
    diamondColor: string,
    diamondWeight: number,
    diamondSize: string,
    diamondPieces: number,
    availableNow: boolean,
    availableOnOrder: boolean,
    availableOnEmi: boolean,
  };

const initialState: ProductStateData[] = [];
type SetProductAction = {
  payload: ProductStateData
  type?: string
}
type SetProductsAction = {
  payload: ProductStateData[]
  type?: string
}


const productSlice = createSlice({
  name: 'products',
  initialState,

  reducers: {
    setProducts (state: ProductStateData[], action: SetProductsAction) {
      state = action.payload
      return state;
  },

    setAddProduct(state: ProductStateData[], action:SetProductAction) {
      state.push(action.payload);
      return state;
    },
    resetProducts() {
      return initialState;
    },
    deleteProduct(state: ProductStateData[], action) {
      state.splice(action.payload, 1)
    }
  },
});



export const {setProducts, setAddProduct, resetProducts, deleteProduct } = productSlice.actions;
export default productSlice.reducer;
