import { createSlice, PayloadAction } from "@reduxjs/toolkit";




const loadingSlice = createSlice({
    name: 'loading',
    initialState: false,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return action.payload;
        },
    },
});

export const { setLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
