import { css,StyleSheet} from "aphrodite";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ProductsRepository } from "../../../repositories/productRepository";
import { DocumentData } from "firebase/firestore";
import Image from "./image.component";
//import e from "express";

let initialDetails:DocumentData;

export function getID(product:DocumentData){
    initialDetails = product;
}

export const EditProducts = () =>{
    const navigate = useNavigate();
    const updateProductDetails = useRef({
        productTagNumber:initialDetails.productTagNumber||'',
        productName:initialDetails.productName||'',
        description:initialDetails.description||'',
        makingCharges: initialDetails.makingCharges||0,
        timeTakenForMaking: initialDetails.timeTakenForMaking||0,
        category:initialDetails.category||'',
        subCategory:initialDetails.subCategory||'',
        image:initialDetails.image||'',
        goldWeight: initialDetails.goldWeight||0,
        goldQuality:initialDetails.goldQuality||'',
       // goldCost: initialDetails.goldCost||0,
        goldColor:initialDetails.goldColor||'',
        stoneType:initialDetails.stoneType||'',
        stoneWeight: initialDetails.stoneWeight||0,
        stonePieces: initialDetails.stonePieces||0,
        stoneCost: initialDetails.stoneCost||0,
        diamondType:initialDetails.diamondType||'',
        diamondWeight: initialDetails.diamondWeight||0,
        diamondQuality: initialDetails.diamondQuality||0,
        diamondPieces: initialDetails.diamondPieces||0,
       // diamondCost: initialDetails.diamondCost||0, 
        available: initialDetails.available||false,
        availableOnOrder: initialDetails.availableOnOrder||false,
        availableOnEmi: initialDetails.availableOnEmi||false,
    })
    function handleUpdate(){
        
        ProductsRepository.editProduct(initialDetails.docId,updateProductDetails.current);
        navigate("/displayProduct");
    }
    const handleImageUpload = (imageUrl:string) => {
         updateProductDetails.current.image = imageUrl;
      };
        return (
            <>
            <div>
            
                <h4 className={css(style.heading)}>Product Details</h4>
                <div className={css(style.parent)}>
                    <div className={css(style.box1)}>

                        <div className={css(style.row)}>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Product Tagnumber</span>
                                <input 
                                  className={css(style.input)}
                                  type="text"
                                   name="productTagNumber"
                                  defaultValue={initialDetails.productTagNumber}
                                   onChange={(e)=>updateProductDetails.current.productTagNumber=e.target.value}
                                   />
                            </div>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Product Name</span>
                                <input 
                                className={css(style.input)} 
                                type="text"
                                name="productName"
                                defaultValue={initialDetails.productName}
                                onChange={(e)=>updateProductDetails.current.productName=e.target.value.toUpperCase()} />
                            </div> 
                        </div>

                        <div className={css(style.inputBox,style.discription)}>
                          <span className={css(style.span)}>Description</span>
                          <input 
                          className={css(style.input,style.discriptionInput)}  
                          type="text"
                          name="description"
                          defaultValue={initialDetails.description}
                          onChange={(e)=>updateProductDetails.current.description=e.target.value} />
                        </div>

                        <div className={css(style.row)}>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Making Charges (in %)</span>
                                <input className={css(style.input)} type="number" 
                                name="makingCharges"
                                defaultValue={initialDetails.makingCharges}
                                onChange={(e)=>updateProductDetails.current.makingCharges=e.target.value}/>
                            </div>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Time Taken For Making (in days)</span>
                                <input className={css(style.input)} name="timeTakenForMaking"  type="number" 
                                defaultValue={initialDetails.timeTakenForMaking}
                                onChange={(e)=>updateProductDetails.current.timeTakenForMaking=e.target.value}/>
                            </div>
                       </div>

                       <div className={css(style.row)}>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Category</span>
                                <input className={css(style.input)}  type="text" 
                                name="category"   
                                defaultValue={initialDetails.category}
                                onChange={(e)=>updateProductDetails.current.category=e.target.value}/>
                            </div>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>SubCategory</span>
                                <input className={css(style.input)}  type="text" 
                                name="subCategory"
                                defaultValue={initialDetails.subCategory}
                                onChange={(e)=>updateProductDetails.current.subCategory=e.target.value}/>
                            </div>
                        </div>

                    </div>

                    <div className={css(style.box2)}>
                        <Image onImageUpload={handleImageUpload}
                              existingImage={initialDetails.image}/>
                    </div>
                </div>


                <h4 className={css(style.heading)}>Gold Details</h4>
                <div className={css(style.parent)}>
                    <div className={css(style.box1)}>
                        <div className={css(style.row)}>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Gold Weight  (in gms)</span>
                                <input className={css(style.input)}  type="number" 
                                name="goldWeight"
                                defaultValue={initialDetails.goldWeight}
                                onChange={(e)=>updateProductDetails.current.goldWeight=e.target.value}/>
                            </div>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Gold Quality</span>
                                <div className={css(style.Quality)}>
                                <div className={css(style.qualityDiv)}>
                                    <input className={css(style.radioInput)} type="radio"  name="goldQuality"
                                     value='18K'
                                     checked={updateProductDetails.current.goldQuality==='18K'}
                                    onChange={(e)=>updateProductDetails.current.goldQuality=e.target.value}/>
                                    <label>18K</label>
                                </div>
                                <div className={css(style.qualityDiv)}>
                                    <input className={css(style.radioInput)} type="radio" name="goldQuality"
                                    value='22K'
                                     checked={updateProductDetails.current.goldQuality==='22K'}
                                    onChange={(e)=>updateProductDetails.current.goldQuality=e.target.value}/>
                                    <label>22K</label>
                                </div>
                                <div className={css(style.qualityDiv)}>
                                    <input className={css(style.radioInput)} type="radio" name="goldQuality"
                                    value='24K'
                                     checked={updateProductDetails.current.goldQuality==='24K'}
                                    onChange={(e)=>updateProductDetails.current.goldQuality=e.target.value}/>
                                    <label>24K</label>
                                </div>  
                                </div>
                                
                            </div> 
                        </div>                
                    </div>

                    <div className={css(style.box2)}>
                        <div className={css(style.row)}>
                                {/* <div className={css(style.inputBox)}>
                                    <span className={css(style.span)}>Gold Cost (in INR)</span>
                                    <input className={css(style.input)}  type="number" 
                                    name="goldCost"
                                    defaultValue={initialDetails.goldCost}
                                    onChange={(e)=>updateProductDetails.current.goldCost=e.target.value}/>
                                </div> */}
                                <div className={css(style.inputBox)}>
                                    <span className={css(style.span)}>Gold Color</span>
                                    <select className={css(style.input)}  name="goldColor"
                                    onChange={(e)=>updateProductDetails.current.goldColor=e.target.value}>
                                        <option >Select Gold Color</option>
                                        <option>Golden</option>
                                        <option>Silver</option>
                                        <option>RoseGold</option>
                                    </select>
                                    
                                </div>
                        </div>
                    </div>               
                </div>

                <h4 className={css(style.heading)}>Stone Details</h4>
                <div className={css(style.parent)}>
                    <div className={css(style.box1)}>
                        <div className={css(style.row)}>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Stone Type</span>
                                <select className={css(style.input)}  name="stoneType"
                                 onChange={(e)=>updateProductDetails.current.stoneType=e.target.value}>
                                     <option>Select Stone Type</option>
                                     <option>GemStones</option>
                                      <option value="AD">AD</option>
                                     <option value="ZD">ZD</option>
                                 </select>  
                                </div>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Stone Weight (in carat)</span>
                                <input className={css(style.input)}  type="number" 
                                name="stoneWeight"
                                defaultValue={initialDetails.stoneWeight}
                                onChange={(e)=>updateProductDetails.current.stoneWeight=e.target.value}/>
                            </div>                         
                        </div>                     
                    </div>

                    <div className={css(style.box2)}>
                        <div className={css(style.row)}>
                                <div className={css(style.inputBox)}>
                                    <span className={css(style.span)}>Stone Pieces </span>
                                    <input className={css(style.input)}  type="number" 
                                    name="stonePieces"
                                    defaultValue={initialDetails.stonePieces}
                                    onChange={(e)=>updateProductDetails.current.stonePieces=e.target.value}/>
                                </div>

                                <div className={css(style.inputBox)}>
                                    <span className={css(style.span)}>Stone Cost (in INR)</span>
                                    <input className={css(style.input)}  type="number" 
                                    name="stoneCost"
                                    defaultValue={initialDetails.stoneCost}
                                    onChange={(e)=>updateProductDetails.current.stoneCost=e.target.value}/>
                                </div>
                                
                        </div>
                    </div>            
               </div>

               <h4 className={css(style.heading)}>Diamond Details</h4>
                <div className={css(style.parent)}>
                    <div className={css(style.box1)}>
                        <div className={css(style.row)}>
                            <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Diamond Type</span>
                                <select className={css(style.input)}
                                name="diamondType"
                                onChange={(e)=>updateProductDetails.current.diamondType=e.target.value}>
                                     <option>Select Diamond Type</option>
                                     <option>Ruby</option>
                                     <option>White Diamond</option>
                                     <option>other</option>
                                 </select>  
                                </div>
                                <div className={css(style.inputBox)}>
                                <span className={css(style.span)}>Diamond Quality</span>
                                <div className={css(style.Quality)}>
                                    <div className={css(style.qualityDiv)}>
                                        <input className={css(style.radioInput)}  type="radio"  name="diamondQuality" 
                                        value='1'
                                        checked={updateProductDetails.current.diamondQuality===1}
                                         onChange={(e)=>updateProductDetails.current.diamondQuality=e.target.value}/>
                                        <label>1</label>
                                    </div>
                                    <div className={css(style.qualityDiv)}>
                                        <input className={css(style.radioInput)}  type="radio" name="diamondQuality"  
                                        value='1.25'
                                         checked={updateProductDetails.current.diamondQuality===1.25}                                     
                                         onChange={(e)=>updateProductDetails.current.diamondQuality=e.target.value}/>
                                        <label>1.25</label>
                                    </div>
                                    <div className={css(style.qualityDiv)}>
                                    <input className={css(style.radioInput)}  type="radio" name="diamondQuality"
                                    value='1.50'
                                      checked={updateProductDetails.current.diamondQuality===1.50}
                                     onChange={(e)=>updateProductDetails.current.diamondQuality=e.target.value}/>
                                    <label>1.50</label>
                                    </div>
                                    <div className={css(style.qualityDiv)}>
                                    <input className={css(style.radioInput)}  type="radio" name="diamondQuality"
                                    value='2'
                                      checked={updateProductDetails.current.diamondQuality===2}
                                     onChange={(e)=>updateProductDetails.current.diamondQuality=e.target.value}/>
                                    <label>2</label>
                                    </div>
                                    
                                </div>                      
                        </div>       
                        
                                
                            </div>              
                    </div>

                    <div className={css(style.box2)}>
                        <div className={css(style.row)}>
                                <div className={css(style.inputBox)}>
                                    <span className={css(style.span)}>Diamond Pieces </span>
                                    <input className={css(style.input)}  type="number" 
                                    name="diamondPieces"
                                    defaultValue={initialDetails.diamondPieces}
                                     onChange={(e)=>updateProductDetails.current.diamondPieces=e.target.value}/>
                                </div>
                                
                                <div className={css(style.inputBox)}>
                                    <span className={css(style.span)}>Diamond Weight (in carat)</span>
                                    <input className={css(style.input)}  type="number" 
                                    name="diamondWeight"
                                    defaultValue={initialDetails.diamondWeight}
                                    onChange={(e)=>updateProductDetails.current.diamondWeight=e.target.value}/>
                                </div>   
                                {/* <div className={css(style.inputBox)}>
                                    <span className={css(style.span)}>diamond Cost (in INR)</span>
                                    <input className={css(style.input)}  type="number" 
                                     name="diamondCost"
                                     defaultValue={initialDetails.diamondCost}
                                     onChange={(e)=>updateProductDetails.current.diamondCost=e.target.value}/>
                                </div> */}
                                
                        </div>
                    </div>            
               </div>   
               <h4 className={css(style.heading)}>Availability</h4>
                
                    <div className={css(style.box1)}>
                        <div className={css(style.row)}>
                            <label>
                                <input type="checkbox" name="available" checked={updateProductDetails.current.available} onChange={(e)=>updateProductDetails.current.available=e.target.checked} />
                                Available
                            </label>
                            <label>
                                <input type="checkbox" name="availableOnOrder" checked={updateProductDetails.current.availableOnOrder} onChange={(e)=>updateProductDetails.current.availableOnOrder=e.target.checked} />
                                Available On Order
                            </label>
                            <label>
                                <input type="checkbox" name="availableOnEmi" checked={updateProductDetails.current.availableOnEmi} onChange={(e)=>updateProductDetails.current.availableOnEmi=e.target.checked} />
                                Available On EMI
                            </label>
                        
                       </div> 
              </div>    
             
           </div>
           <div className={css(style.buttonDiv)}>
             <button onClick = {handleUpdate} className={css(style.SubmitButton)}>Update</button>
           </div>
        </>
    )
    

}
const style = StyleSheet.create({
    parent:
    {
        display:'grid',
        gridTemplateColumns:'1fr 1fr',
        columnGap:'10px',
    },
    heading:{
        fontWeight:'bolder',
        fontSize:'20px'
    },
    box1:{
        
        padding:'30px',
        boxShadow: '0px 1px 5px #aaa',
        fontSize:'18px',
    },
    row:{
        display:'flex',
        justifyContent:'space-between',
    },
    inputBox:{
        display:'flex',
        flexDirection:'column',
        width:'47%',
        // height:'50px',
        marginBottom:'20px',
        },
    input:{
        position:'relative',
        width:'100%',
        border:'2px solid #aaa',
        outline:'none',
        background:'none',
        padding:'10px',
        borderRadius:'10px',
        fontSize:'18px',
        marginTop:'10px',
        boxShadow:'0px 1px 5px #aaa'

},
span:{
    
    fontSize:'18px',
},
discription:{
    width:'100%',
},
discriptionInput:{
    height:'80px',
},
    box2:
    {
        padding:'30px',
        boxShadow: '0px 1px 5px #aaa',
        fontSize:'18px',
        paddingBottom:'0px',
    },
    logoCircle:{
        width: '3.5rem',
        height: '3.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor:'#eee',
       
    },
    imageBody:{
        padding:'1rem 1.5rem',
    },
    imageTitle:{
        fontWeight:'bold'
    },
    imageDescription:{
        color:'#aaa'
    },
    imageButton:{
        marginTop:'1.25rem',
        border:'1px dashed #aaa',
        backgroundColor:'transparent',
        padding:'3rem',
        width:'80%',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        textAlign:'center',
        ':hover':{
            border:'1px dashed #f0bf4c'
        },
        ':focus':{
            border:'1px dashed #f0bf4c',
        },
       
    },
    imageIcon:{
        display:'block',
        width:'2.25rem',
        height:'2.25rem'
    },
    uploadTitle:{
        marginTop:'1rem',
        display:'block',
        fontWeight:'bolder',
        color:'#000'
    },
    uploadDescription:{
        display:'block',
        color:'#aaa',
    },
    imagePreview: {
        marginTop: '20px',
        maxWidth: '100%',
        height: 'auto',
        borderRadius: '4px',
    },  
    dragActive: {
        border: '2px solid #f0bf4c',
        backgroundColor: '#f0bf4c',
    },
    strong:{
        color:'#f0bf4c',
        fontWeight:'bold'
    },
    buttonBody:{
            padding: '1rem 1.5rem 1.5rem',
            display: 'flex',
            justifyContent:'flex-end',           
    },
    button:{
        padding:'.5rem 1rem',
        fontWeight: 'bold',
        border: '2px solid #aaa',
        borderRadius: '.25rem',
        marginLeft: '.75rem',
        boxShadow:'0px 1px 5px #aaa'
    },
    cancelButton:{
        backgroundColor:'transparent'
    },
    uploadButton:{
        backgroundColor:'#f0bf4c',
        marginRight: '-1.5rem',
    },
    Quality:
    {
        ':first-of-type':{
        display:'flex',
        alignItems:'flex-start',
        marginBottom:'5px',
        marginTop:'15px'
    }
        
    },
   qualityDiv:{
    marginRight:'10px'
   },
   radioInput:{
    appearance:'none',
    borderRadius:'50%',
    width:'16px',
    height:'16px',
    border:'2px solid #aaa',
    marginRight:'5px',
    position:'relative',            
    boxShadow:'0px 1px 5px #aaa',
    ':checked':{
            border: '6px solid black',   
    }
   },
   label:{
    marginRight:'20px',
    lineHeight:'32px',
   },
   buttonDiv:{
    marginTop:'30px',
    display:'flex',
    justifyContent:'center',
   
   },
   SubmitButton:{
    alignItems:'center',  
    padding:'10px',
    fontSize:'18px',
    outline:'none',
    border:'none',
    borderRadius:'10px',
    width:'200px',
    boxShadow:'0px 1px 10px #BF9850',
    backgroundColor:'#f0bf4c'
   },
   availability:{
    width:'100%'
   }
})
      