import {useRef} from 'react';
import { RateDiffRepository, RateRepository } from '../../repositories/goldRateRepository';
import DisplayRates from './ratesubcomponents/displayrates.component';
import { updateGoldRateRef, updateSilverRateRef } from '../../library/rateHelper';
import { useDispatch } from 'react-redux';
import { setGoldRate } from '../../store/goldRateSlice';
import {css,StyleSheet} from 'aphrodite';
import commonCss, { colsGap, margin, rowsGap, color, width } from '../../commonCss/commonCss';
import { darkAmberColor, primaryColor, silverColor } from '../../theme';
import { ButtonMedium } from '../button.component';
import DiamondRates from './ratesubcomponents/diamondrates.component';
import { ConfigProvider } from 'antd';
import { Timestamp } from 'firebase/firestore';




export default function Rate(){
    const rateRef = useRef({gold: {'18K':0,'22K':0,'24K':0,'24K995':0}, silver: [0,0,0,0]})
    const changeRef = useRef(0)
    const dispatch = useDispatch();

    function handleSubmit() {
        const currentRate= {gold: {...rateRef.current.gold}, silver: [...rateRef.current.silver], timestamp: Timestamp.now().toMillis()}
        if (currentRate.gold['24K995']<=0 || currentRate.silver[3]<=0 ) {
            alert("Can not have Zero rate");
            return
        }
        RateRepository.AddRate(currentRate).then(() => {
            alert("Rate is successfully updated")
            dispatch(setGoldRate(currentRate))
        });
    };

    function handleDiff(){
        RateDiffRepository.UpdateDiff(changeRef.current);
    }


    return <div className={css(commonCss.flexColumn, rowsGap("30px"))}>
        <DisplayRates />
        <div>
            <div className={css(commonCss.flexRow, commonCss.alignItemsCenter, commonCss.justifyCenter, margin('1.5rem'))}>
                <div className={css(styles.span)}>Edit Prices</div>
            </div>
            <div className={css(styles.form, commonCss.flexRow, commonCss.justifySpaceBetween, colsGap('1.4rem'), commonCss.flexWrap, rowsGap('1.4rem'), commonCss.alignItemsCenter)}>
                <div>
                    <label className={css(styles.label,color(darkAmberColor))}>Gold Rate 24K (99.5%)</label>
                    <input
                        type="number"
                        onChange={(e) => updateGoldRateRef(e, rateRef)}
                        className={css(styles.input)}
                        required
                    />
                </div>
                <div>
                    <label className={css(styles.label,color(silverColor))}>Silver (100%)</label>
                    <input
                        type="number"
                        onChange={(e) => updateSilverRateRef(e, rateRef)}
                        className={css(styles.input)}
                        required
                    />
                </div>
                <ButtonMedium text='Save' color={primaryColor} onClick={handleSubmit} className={css(styles.saveButton)}></ButtonMedium>
            <div className={css(styles.form, commonCss.flexRow, commonCss.justifySpaceBetween, colsGap('1.4rem'), commonCss.flexWrap, rowsGap('1.4rem'), commonCss.alignItemsCenter)}>
                Price Diff :
                <input type='number' onChange={(e)=> {changeRef.current = parseInt(e.target.value)}} className={css(styles.input)}/>
                <ButtonMedium text='Apply' color={primaryColor} onClick={handleDiff} className={css(styles.saveButton)}></ButtonMedium>
            </div>
            </div>
        </div>
        <ConfigProvider theme={{token:{colorPrimary: primaryColor}}}>
        <DiamondRates />
        </ConfigProvider>
    </div>
}

const styles=StyleSheet.create({
    span:{
        boxShadow: '0px 0px 2px #aaa',
        padding:'1rem',
        fontWeight:'bold',
    },

    form:{
        padding:'40px',
        boxShadow: '0px 1px 5px #aaa',
    },

    label:{
        padding:'5px',
        fontWeight:'bold',
    },

    input:{
        margin: '5px',
        padding:'10px',
        width: 'auto',
        color:'grey',
        border:'none',
        boxShadow: '0px 0px 3px #D9D9D9',
        ':focus':{outline:'none',boxShadow:'0px 0px 3px #FFCC01'},
                
    },
    saveButton: {
        borderRadius: '20px',
        fontWeight: 'bold'
    }
})




