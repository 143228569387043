import { css, StyleSheet } from "aphrodite"
import commonCss, { colsGap, rowsGap } from "../../../commonCss/commonCss"

import { Select } from 'antd';
import { useRef, useState } from "react";
import { primaryColor } from "../../../theme";
import { ButtonMedium } from "../../button.component";
import { DiamondRateRepository } from "../../../repositories/diamondRateRepository";

const items = [

    {label: 'VVS EF',     value: 'vvs_EF'},
    {label: 'VVS-VS FG',  value: 'vvs_vs_FG'},
    {label: 'VVS-VS GH',  value: 'vvs_vs_GH'},
    {label: 'VVS-VS HI',  value: 'vvs_vs_HI'},

    {label: 'VS-SI FG',   value: 'vs_si_FG'},
    {label: 'VS-SI GH',   value: 'vs_si_GH'},
    {label: 'VS-SI HI',   value: 'vs_si_HI'},
    {label: 'VS-SI IJ',   value: 'vs_si_IJ'},

    {label: 'SI-I FG',    value: 'si_i_FG'},
    {label: 'SI GH',      value: 'si_GH'},
    {label: 'SI IJ',      value: 'si_IJ'},
    {label: 'SI JK',      value: 'si_JK'}

];

export default function DiamondRates(){

    const diamondType = useRef('');

    const rateData = useRef(
        {
            '+2to6.5':0,           
            '+6.5to8':0,
            '+8to9':0,
            '+9to10':0,
            '+10to11':0,
            '-2':0,
            '+11-12':0,
            '+12-14':0,    
            '1/6(0.14-0.17)':0,
            '1/5(0.18-0.22)':0,
            '1/4(0.23-0.29)':0,
            '1/3(0.30-0.37)':0
        }
    );

    function handlesubmit(){
        DiamondRateRepository.addRate({...(rateData.current)},diamondType.current)
    }

    
    return<div className={css(styles.form, commonCss.flexColumn, commonCss.justifySpaceBetween, colsGap('1.4rem'), commonCss.flexWrap, rowsGap('1.4rem'))}>
        <div className={css(commonCss.justifySpaceBetween,commonCss.flexRow,commonCss.alignItemsCenter)}>
            <span className={css(styles.title)}>Diamond</span> 
            <Select
                // defaultValue="lucy"
                style={{ width: 120 }}
                allowClear
                options={items}
                placeholder="Type"
                onSelect={(value)=>{diamondType.current = value}}/>
        </div>
    
        <div>
            <div className={css(styles.divli)}>
                <div className={css(styles.type)}>+2 to 6.5 </div>
                <input type='text' className={css(styles.input)} onChange={(e)=>{rateData.current['+2to6.5']=parseFloat(e.target.value)}}/>
            </div>

            <div className={css(styles.divli)}>
                <div className={css(styles.type)}>+6.5 to 8 </div>
                <input type='text' className={css(styles.input)} onChange={(e)=>{rateData.current['+6.5to8']=parseFloat(e.target.value)}}/>
            </div>

            <div className={css(styles.divli)}>
                <div className={css(styles.type)}>+8 to 9</div>
                <input type='text' className={css(styles.input)} onChange={(e)=>{rateData.current['+8to9']=parseFloat(e.target.value)}}/>
            </div>

            <div className={css(styles.divli)}>
                <div className={css(styles.type)}>+9 to 10 </div>
                <input type='text' className={css(styles.input)} onChange={(e)=>{rateData.current['+9to10']=parseFloat(e.target.value)}}/>
            </div>

            <div className={css(styles.divli)}>
                <div className={css(styles.type)}>+10 to 11 </div>
                <input type='text' className={css(styles.input)} onChange={(e)=>{rateData.current['+10to11']=parseFloat(e.target.value)}}/>
            </div> 

            <div className={css(styles.divli)}>
                <div className={css(styles.type)}>-2 </div>
                <input type='text' className={css(styles.input)} onChange={(e)=>{rateData.current['-2']=parseFloat(e.target.value)}}/>
            </div>

            <div className={css(styles.divli)}>
                <div className={css(styles.type)}>+11-12 </div>
                <input type='text' className={css(styles.input)} onChange={(e)=>{rateData.current['+11-12']=parseFloat(e.target.value)}}/>
            </div>

            <div className={css(styles.divli)}>
                <div className={css(styles.type)}>+12-14 </div>
                <input type='text' className={css(styles.input)} onChange={(e)=>{rateData.current['+12-14']=parseFloat(e.target.value)}}/>
            </div>

            <div className={css(styles.divli)}>
                <div className={css(styles.type)}>1/6(0.14-0.17) </div>
                <input type='text' className={css(styles.input)} onChange={(e)=>{rateData.current['1/6(0.14-0.17)']=parseFloat(e.target.value)}}/>
            </div>

            <div className={css(styles.divli)}>
                <div className={css(styles.type)}>1/5(0.18-0.22) </div>
                <input type='text' className={css(styles.input)} onChange={(e)=>{rateData.current['1/5(0.18-0.22)']=parseFloat(e.target.value)}}/>
            </div>

            <div className={css(styles.divli)}>
                <div className={css(styles.type)}>1/4(0.23-0.29) </div>
                <input type='text' className={css(styles.input)} onChange={(e)=>{rateData.current['1/4(0.23-0.29)']=parseFloat(e.target.value)}}/>
            </div>

            <div className={css(styles.divli)}>
                <div className={css(styles.type)}>1/3(0.30-0.37) </div>
                <input type='text' className={css(styles.input)} onChange={(e)=>{rateData.current['1/3(0.30-0.37)']=parseFloat(e.target.value)}}/>
            </div>
    
        </div>
        <div className={css(styles.buttonDiv)}>
            <ButtonMedium onClick={handlesubmit} color={primaryColor} className={css(styles.saveButton)} text={'Save'}></ButtonMedium>
        </div>

    </div>
}


const styles = StyleSheet.create({
    form:{
        padding:'40px',
        boxShadow: '0px 1px 5px #aaa',
    },
    title:{
        padding:'0.5rem',
        fontWeight:'bold',
        textAlign:'center',
    },
    input:{
        color:'grey',
        fontSize:'15px',
        alignItems: 'center',
        marginLeft:'5px',
        marginRight:'5px',
        width:'30%',
        maxWidth:'200px',
        padding:'0.3rem',
        boxShadow: '0px 0px 3px #D9D9D9',
        border:'none',
        ':focus':{outline:'none',boxShadow:'0px 0px 3px #FFCC01'},
    },

    divli:{
        display: "flex",
        padding:'0.9rem',
        color:'grey',
        fontSize:'17px',
        justifyContent: 'space-between',
        alignItems: 'center'
    },

    type:{
        fontWeight:'bold',
    },

    buttonDiv:{
        display:'flex',
        // boxShadow: '0px 0px 3px silver',
        marginTop:'20px',
        flexDirection:'row-reverse'
        
    },

    saveButton:{
        borderRadius: '20px',
        fontWeight: 'bold',
        width:'150px',
        marginRight:'auto',
        marginLeft:'auto'
    }

})