


export const formatToINR = (val: number) => {
    return Intl.NumberFormat('en-us', {
        style: 'currency',
        currency: 'INR',
    }).format(val);
}

export const capitalize = (val: string) => val.slice(0,1).toUpperCase() + val.slice(1).toLowerCase();