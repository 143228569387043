interface DiamondRateConstructorObjectInterface {
    // _vvs_EF: DiamondCaratSubType,
    // _vvs_vs_FG: DiamondCaratSubType,
    // _vvs_vs_GH: DiamondCaratSubType,
    // _vvs_vs_HI: DiamondCaratSubType,

    // _vs_si_FG: DiamondCaratSubType,
    // _vs_si_GH: DiamondCaratSubType,
    // _vs_si_HI: DiamondCaratSubType,
    // _vs_si_IJ: DiamondCaratSubType,

    // _si_i_FG: DiamondCaratSubType,
    // _si_GH: DiamondCaratSubType, 
    // _si_IJ: DiamondCaratSubType, 
    // _si_JK: DiamondCaratSubType,
    '+2to6.5': string,
    '+6.5to8': string,
    '+8to9': string,
    '+9to10': string,
    '+10to11': string,
    '-2': string,
    '+11-12': string,
    '+12-14': string,
    '1/6(0.14-0.17)': string,
    '1/5(0.18-0.22)': string,
    '1/4(0.23-0.29)': string,
    '1/3(0.30-0.37)': string 
}


export class DiamondRateDTO{
    private constructor (private diamondRate: DiamondRateConstructorObjectInterface) {}

    get rate(){
        return {
            '+2to6.5': this.diamondRate["+2to6.5"],
            '+6.5to8': this.diamondRate["+6.5to8"],
            '+8to9': this.diamondRate["+8to9"],
            '+9to10': this.diamondRate["+9to10"],
            '+10to11': this.diamondRate["+10to11"],
            '-2': this.diamondRate["-2"],
            '+11-12': this.diamondRate["+11-12"],
            '+12-14': this.diamondRate["+12-14"],
            '1/6(0.14-0.17)': this.diamondRate["1/6(0.14-0.17)"],
            '1/5(0.18-0.22)': this.diamondRate["1/5(0.18-0.22)"],
            '1/4(0.23-0.29)': this.diamondRate["1/4(0.23-0.29)"],
            '1/3(0.30-0.37)': this.diamondRate["1/3(0.30-0.37)"] 
        }
    }

    

    static fromJSON(data: any){
        return new DiamondRateDTO({
                '+2to6.5': data['+2to6.5'],
                '+6.5to8': data['+6.5to8'],
                '+8to9': data['+8to9'],
                '+9to10': data['+9to10'],
                '+10to11': data['+10to11'],
                '-2': data['-2'],
                '+11-12': data['+11-12'],
                '+12-14': data['+12-14'],
                '1/6(0.14-0.17)': data['1/6(0.14-0.17)'],
                '1/5(0.18-0.22)': data['1/5(0.18-0.22)'],
                '1/4(0.23-0.29)': data['1/4(0.23-0.29)'],
                '1/3(0.30-0.37)': data['1/3(0.30-0.37)']
        })
    }
}