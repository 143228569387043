import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const errorSlice = createSlice({
    name: 'error',
    initialState: '',
    reducers: {
        setError(state, action: PayloadAction<string>) {
            return action.payload;
        },
        resetError() {
            return '';
        },
    },
});

export const { setError, resetError } = errorSlice.actions;
export default errorSlice.reducer;