import {css,StyleSheet} from 'aphrodite';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useRef } from 'react';
import { CustomersRepository } from '../../repositories/customerRepository';
import commonCss, { colsGap, maxWidth, minWidth, padding, rowsGap } from '../../commonCss/commonCss';
import { darkAmberColor, primaryColor, softBlack, white } from '../../theme';
import { ButtonMedium } from '../button.component';
import { addCustomer } from '../../store/customersSlice';
import ListCustomers from './customersubcomponents/listcustomer.component';
import { StoreSelector } from '../../store/store';
import { ConfigProvider, InputNumber } from 'antd';



const labelByCustomerPropKey: {[key: string]: string} = {
    name: "Name",
    contact: "Contact",
    email: "E-mail",
    gender: "Gender",
    age: "Age",
    locality: "Locality",
    city: "City",
    state: "State",
    country: "Country",
    pincode: "Pin Code",
    membershipId: "Membership Id"
}



export default function Customer(){
    const dispatch = useDispatch();
    const customerDetails=useRef({name:'', contact:'', email:'', gender:'Female', age:'', locality:'', city:'Ghazipur', state:'Uttar Pradesh', country:'India', pincode:'233001', membershipId:''})
    const saveCustomer = useCallback(()=>{
        if(customerDetails.current.contact.length>10){
            alert('Contact Number more than 10 digits')
        }else{
            CustomersRepository.addCustomer({...customerDetails.current})
            .then(customer => dispatch(addCustomer({...customerDetails.current, id: customer.id})))
            .then(()=>{alert("Customer Saved !")})
        }
    }, [])

    return <div className={css(commonCss.flexRow, commonCss.justifySpaceBetween, commonCss.flexWrap, colsGap('20px'), rowsGap('20px'))}>
        <div className={css(styles.form, padding('1.6rem'), minWidth('200px'), maxWidth('800px'))}>
            {Object.keys(customerDetails.current).map((customerProperty: string) => {
                return <div className={css(styles.item)} key={customerProperty}>
                    <label className={css(styles.label)}>{labelByCustomerPropKey[customerProperty]}</label>
                    {customerProperty==='contact'?
                    <div className={css(styles.contactDiv)}>
                    <ConfigProvider theme={{
                        token:{
                            borderRadius:0,
                            colorBorder:'none',
                            lineWidth:0
                        }
                    }}>
                    <InputNumber  
                        style={{ width: '100%' }} 
                        prefix={'+91'} 
                        required
                        className={css(styles.contactInput)}
                        defaultValue={(customerDetails.current as any)[customerProperty]}
                        onChange={(value) =>{(customerDetails.current as any)[customerProperty]=value}}
                    /></ConfigProvider>
                    </div>:
                    <div>
                    <input
                        type="text"
                        onChange={(e) =>{(customerDetails.current as any)[customerProperty]=e.target.value}}
                        className={css(styles.input)}
                        defaultValue={(customerDetails.current as any)[customerProperty]}
                        required
                    /></div>}
                </div>
            })}

            <div className={css(styles.buttonDiv)}>
                <ButtonMedium onClick={() => saveCustomer()} color={primaryColor} className={css(styles.saveButton)} text={'Save'}></ButtonMedium>
            </div>

        </div>

        <div className={css(styles.form,padding('1.6rem'), minWidth('200px'), maxWidth('800px'))}>
            <ListCustomers />   
        </div>
    </div>

}




const styles=StyleSheet.create({

    form:{
        padding:'40px',
        boxShadow: '0px 1px 5px #aaa',  
        display:'grid',
        height: '80vh',
        flex:1,
        overflowY: "auto"
    },

    item:{
        display:'flex',
        justifyContent:'space-between',
        margin:'7px', 
        height: '2.4rem'
    },

    label:{
        padding:'10px',
        fontWeight:'bold',
        color:'#BF9850',

    },

    input:{
        padding:'10px',
        fontSize:'1rem',
        color:'grey',
        border:'none',
        boxShadow: '0px 0px 3px #D9D9D9',
        transitionDuration:'0.2s',
        ':focus':{outline:'none',color:softBlack},
    },

    buttonDiv:{
        display:'flex',
        // boxShadow: '0px 0px 3px silver',
        marginTop:'20px',
        flexDirection:'row-reverse'
        
    },

    saveButton:{
        borderRadius: '20px',
        fontWeight: 'bold',
        width:'200px',
        marginRight:'auto',
        marginLeft:'auto'
    },
    contactDiv:{
        boxShadow: '0px 0px 3px #D9D9D9',
        alignContent: 'center',
        width:'215px'
    },
    contactInput:{
        fontSize:'1rem',
        color:'grey',
        border:'none',
        ':focus':{outline:'none',color:softBlack,border:'none',}
    }



})