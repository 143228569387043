import { Select } from "antd";
import { css, StyleSheet } from "aphrodite";
import { forwardRef, useRef } from "react";
import { useProductActions } from "../../../hooks/useAddProductHook";
import { ProductDetailsState } from "../../../store/productDetailsSlice";
import { TagState } from "../../../store/productTagSlice";

export function DiamondDetails({productDetailsState, tagsState}:{productDetailsState:ProductDetailsState, tagsState:TagState[]}){

    const {updateProductDetails} = useProductActions({productDetailsState, tagsState});

    const diamondClarity = [
        {label:'VVS' , value:'vvs_'},
        {label:'VVS-VS' , value:'vvs_vs_'},
        {label:'VS-SI' , value:'vs_si_'},
        {label:'SI' , value:'si_'},
        {label:'SI-I' , value:'si_i_'},
    ]

    const diamondColor = [
        {value: 'EF'},
        {value: 'FG'},
        {value: 'GH'},
        {value: 'HI'},
        {value: 'IJ'},
        {value: 'JK'},
    ]

    const diamondSize = [
        {value:'+2to6.5'},
        {value:'+6.5to8'},
        {value:'+8to9'},
        {value:'+9to10'},
        {value:'+10to11'},
        {value:'-2'},
        {value:'+11-12'},
        {value:'+12-14'},
        {value:'1/6(0.14-0.17)'},
        {value:'1/5(0.18-0.22)'},
        {value:'1/4(0.23-0.29)'},
        {value:'1/3(0.30-0.37)'},
    ]

    const ClarityColor = useRef({color: '', clarity: ''})

    // const diamondQuality = [
    //     {value: 1},
    //     {value: 1.25},
    //     {value: 1.50},
    //     {value: 2}
    // ]

    return<>
    <h4 className={css(style.heading)}>Diamond Details</h4>
    <div className={css(style.parent)}>
        <div className={css(style.box1)}>
            <div className={css(style.row)}>
                <div className={css(style.inputBox)}>
                    <span className={css(style.span)}>Diamond Clarity</span> 
                        <Select
                            // defaultValue="lucy"
                            style={{ width: 120 }}
                            allowClear
                            options={diamondClarity}
                            placeholder="Clarity"
                            onSelect={(value)=>{updateProductDetails({name:'diamondClarity',value: value})}}/>
                </div>
                <div className={css(style.inputBox)}>
                <span className={css(style.span)}>Diamond Color</span>
                    <div className={css(style.Quality)}>
                        <Select
                            // defaultValue="lucy"
                            style={{ width: 120 }}
                            allowClear
                            options={diamondColor}
                            placeholder="Color"
                            onSelect={(value)=>{updateProductDetails({name:'diamondColor',value: value})}}/>
                    </div>
                </div>                         
            </div>       
                        
            <div className={css(style.row)}>

                <div className={css(style.inputBox)}>
                        <span className={css(style.span)}>Diamond Weight (in carat)</span>
                        <input className={css(style.input)}  type="number" 
                            onChange={(e)=>{updateProductDetails({name: 'diamondWeight', value:e.target.value})}}/>
                </div>  
                <div className={css(style.inputBox)}>
                <span className={css(style.span)}>Diamond Size</span>
                    <div className={css(style.Quality)}>
                        <Select
                            // defaultValue="lucy"
                            style={{ width: 150 }}
                            allowClear
                            options={diamondSize}
                            placeholder="Size"
                            onSelect={(value)=>{updateProductDetails({name:'diamondSize',value: value})}}/>
                    </div>
                </div>
                <div className={css(style.inputBox)}>
                    <span className={css(style.span)}>Diamond Pieces </span>
                    <input className={css(style.input)}  type="number" 
                        onChange={(e)=>{updateProductDetails({name: 'diamondPieces', value:e.target.value})}}/>
                </div>
                
            </div>
        </div>            
    </div>   
</>
}


const style = StyleSheet.create({
    parent:{
        display:'flex',
        // gridTemplateColumns:'1fr 1fr',
        flexWrap:'wrap',
        columnGap:'10px',
    },
    heading:{
        fontWeight:'bolder',
        fontSize:'20px'
    },
    box1:{
        // margin:'1.5rem',
        padding:'30px',
        boxShadow: '0px 1px 5px #aaa',
        fontSize:'18px',
        flex:1,
    },
    row:{
        display:'flex',
        flexWrap: 'wrap',
        justifyContent:'space-between',
        alignItems:'center',
    },
    inputBox:{
        display:'flex',
        flexDirection:'column',
        width:'47%',
        // height:'50px',
        marginBottom:'20px',
        },
    input:{
        position:'relative',
        width:'auto',
        // border:'1px solid #aaa',
        outline:'none',
        background:'none',
        padding:'10px',
        borderRadius:'10px',
        fontSize:'18px',
        marginTop:'10px',
        // boxShadow:'0px 1px 5px #aaa',
        // margin: '5px',
        // padding:'10px',
        // width: 'auto',
        color:'grey',
        border:'none',
        boxShadow: '0px 0px 3px #D9D9D9',
        ':focus':{outline:'none',boxShadow:'0px 0px 3px #FFCC01'},

    },
    span:{
        
        fontSize:'18px',
    },
    discription:{
        width:'100%',
    },
    discriptionInput:{
        height:'80px',
    },
    box2:
    {
        padding:'30px',
        boxShadow: '0px 1px 5px #aaa',
        fontSize:'18px',
        paddingBottom:'0px',
        flex:1,
    },
    logoCircle:{
        width: '3.5rem',
        height: '3.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor:'#eee',
       
    },
    imageBody:{
        padding:'1rem 1.5rem',
    },
    imageTitle:{
        fontWeight:'bold'
    },
    imageDescription:{
        color:'#aaa'
    },
    imageButton:{
        margin:'1rem',
        border:'1px dashed #aaa',
        backgroundColor:'transparent',
        padding:'1.5rem',
        width:'80%',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        textAlign:'center',
        ':hover':{
            border:'1px dashed #f0bf4c'
        },
        ':focus':{
            border:'1px dashed #f0bf4c',
        },
       
    },
    imageIcon:{
        display:'block',
        width:'2.25rem',
        height:'2.25rem'
    },
    uploadTitle:{
        marginTop:'1rem',
        display:'block',
        fontWeight:'bolder',
        color:'#000'
    },
    uploadDescription:{
        display:'block',
        color:'#aaa',
    },
    imagePreview: {
        marginTop: '20px',
        maxWidth: '100%',
        height: 'auto',
        borderRadius: '4px',
    },  
    dragActive: {
        border: '2px solid #f0bf4c',
        backgroundColor: '#f0bf4c',
    },
    strong:{
        color:'#f0bf4c',
        fontWeight:'bold'
    },
    buttonBody:{
            padding: '1rem 1.5rem 1.5rem',
            display: 'flex',
            justifyContent:'flex-end',           
    },
    button:{
        padding:'.5rem 1rem',
        fontWeight: 'bold',
        border: '2px solid #aaa',
        borderRadius: '.25rem',
        marginLeft: '.75rem',
        boxShadow:'0px 1px 5px #aaa'
    },
    cancelButton:{
        backgroundColor:'transparent'
    },
    uploadButton:{
        backgroundColor:'#f0bf4c',
        marginRight: '-1.5rem',
    },
    Quality:{
        ':first-of-type':{
        display:'flex',
        alignItems:'flex-start',
        marginBottom:'5px',
        marginTop:'15px'
    }
        
    },
   qualityDiv:{
    marginRight:'10px'
   },
   radioInput:{
    appearance:'none',
    borderRadius:'50%',
    width:'16px',
    height:'16px',
    border:'2px solid #aaa',
    marginRight:'5px',
    position:'relative',            
    boxShadow:'0px 1px 5px #aaa',
    ':checked':{
            border: '6px solid black',   
    }
   },
   label:{
    marginRight:'20px',
    lineHeight:'32px',
   },
   buttonDiv:{
    marginTop:'30px',
    display:'flex',
    justifyContent:'center',
   
   },
   SubmitButton:{
    alignItems:'center',  
    padding:'10px',
    fontSize:'18px',
    outline:'none',
    border:'none',
    borderRadius:'10px',
    width:'200px',
    boxShadow:'0px 1px 10px #BF9850',
    backgroundColor:'#f0bf4c'
   },
   availability:{
    width:'100%'
   }
})