import { ProductStateData } from "../store/productSlice";
import { GoldRateDTO } from "./goldRate";
interface productConstructorObjectInterface{
    _productTagNumber: string,
    _productName: string,
    _description: string,
    _makingCharges: number,
    _timeTakenForMaking: number,
    _category: string,
    _subCategory: string,
    _image: string,
    _goldWeight: number,
    _goldKarat: string,
    _goldColor: string,
    _goldWastage: number,
    _stoneType: string,
    _stoneWeight: number,
    _stonePieces: number,
    _stoneCost: number,
    _diamondClarity: string,
    _diamondColor: string,
    _diamondWeight: number,
    _diamondSize: string,
    _diamondPieces: number,
    _availableNow: boolean,
    _availableOnOrder: boolean,
    _availableOnEmi: boolean,
    _id: string,
}

export class ProductsDTO {
   private constructor(private productData: productConstructorObjectInterface) {}

  get productTagNumber() {
      return this.productData._productTagNumber
  }

  get productName() {
      return this.productData._productName
  }

  get description() {
      return this.productData._description
  }

  get makingCharges() {
      return this.productData._makingCharges 
  }

  get timeTakenForMaking(){
      return this.productData._timeTakenForMaking
  }

  get category() {
      return this.productData._category
  }

  get subCategory() {
      return this.productData._subCategory
  }

  get image() {
      return this.productData._image
  }

  get goldWeight() {
      return this.productData._goldWeight
  }

  get goldKarat() {
      return this.productData._goldKarat
  }

  get goldColor(){
      return this.productData._goldColor
  }

  get goldWastage(){
    return this.productData._goldWastage
  }

  get stoneType() {
      return this.productData._stoneType
  }

  get stoneWeight(){
      return this.productData._stoneWeight
  }

  get stonePieces(){
      return this.productData._stonePieces
  }
  get stoneCost(){
    return this.productData._stoneCost
  }
  get diamondClarity(){
    return this.productData._diamondClarity
  }
  get diamondWeight(){
    return this.productData._diamondWeight
  }
  get diamondColor(){
    return this.productData._diamondColor
  }
  get diamondSize(){
    return this.productData._diamondSize
  }
  get diamondPieces(){
    return this.productData._diamondPieces
  }
  get availableNow(){
    return this.productData._availableNow
  }
  get availableOnOrder(){
    return this.productData._availableOnOrder
  }
  get availableOnEmi(){
    return this.productData._availableOnEmi
  }
  get id(){
    return this.productData._id
  }

  getPriceBreakup(goldRate:GoldRateDTO) {
      const goldKarat = this.goldKarat?.toUpperCase();
      let totalGoldCost = 0;
      if (goldKarat === "18K") {
        totalGoldCost = goldRate.rateOf18K * this.goldWeight;
      } else if (goldKarat === "22K") {
        totalGoldCost = goldRate.rateOf22K * this.goldWeight;
      } {
        totalGoldCost = goldRate.rateOf24K * this.goldWeight;
      }
  
  
      const makingCharges = (this.makingCharges / 100) * totalGoldCost;
      const finalPrice = (totalGoldCost + makingCharges) * 1.03;
      console.log({
        totalGoldCost: totalGoldCost.toFixed(2),
        makingCharges: makingCharges.toFixed(2),
        finalPrice: Math.round(finalPrice),
      })
      return {
        totalGoldCost: totalGoldCost.toFixed(2),
        makingCharges: makingCharges.toFixed(2),
        finalPrice: Math.round(finalPrice),
      };
  }

  static fromJSON(data: any): ProductsDTO {
      return new ProductsDTO({
          _productTagNumber: data.productTagNumber,
          _productName: data.productName, 
          _description: data.description, 
          _makingCharges: data.makingCharges, 
          _timeTakenForMaking: data.timeTakenForMaking, 
          _category: data.category, 
          _subCategory: data.subCategory, 
          _image: data.image, 
          _goldWeight: data.goldWeight,
          _goldKarat: data.goldKarat,
          _goldColor: data.goldColor,
          _goldWastage: data.goldWastage,
          _stoneType: data.stoneType,
          _stoneWeight: data.stoneWeight,
          _stonePieces: data.stonePieces,
          _stoneCost: data.stoneCost,
          _diamondClarity: data.diamondClarity,
          _diamondColor: data.diamondColor,
          _diamondWeight: data.diamondWeight,
          _diamondSize: data.diamondSize,
          _diamondPieces: data.diamondPieces,
          _availableNow: data.availableNow,
          _availableOnOrder: data.availableOnOrder,
          _availableOnEmi: data.availableOnEmi,
          _id:data.id
      })
  }

  toJson():ProductStateData{
    return {
      productTagNumber:this.productTagNumber,
      productName:this.productName,
      description:this.description,
      makingCharges: this.makingCharges,
      timeTakenForMaking: this.timeTakenForMaking,
      category:this.category,
      subCategory:this.subCategory,
      image:this.image,
      goldWeight: this.goldWeight,
      goldKarat:this.goldKarat,
      goldColor:this.goldColor,
      goldWastage: this.goldWastage,
      stoneType:this.stoneType,
      stoneWeight: this.stoneWeight,
      stonePieces: this.stonePieces,
      stoneCost: this.stoneCost,
      diamondClarity:this.diamondClarity,
      diamondColor: this.diamondColor,
      diamondWeight: this.diamondWeight,
      diamondSize: this.diamondSize,
      diamondPieces: this.diamondPieces,
      availableNow:this.availableNow,
      availableOnOrder:this.availableOnOrder,
      availableOnEmi:this.availableOnEmi,
    }       
  }
}
