

import { createSlice } from "@reduxjs/toolkit";


export interface CustomerStateData {
    id?: string
    name: string,
    contact: string,
    age: string,
    gender: string,
    locality: string,
    state: string,
    country: string,
    pincode: string,
    city: string,
    email?: string,
    membershipId?: string
}

const initialState: CustomerStateData[] = [];

type SetCustomerAction = {
    payload: CustomerStateData
    type?: string
}
type SetCustomersAction = {
    payload: CustomerStateData[]
    type?: string
}
const customerSlice = createSlice({
    name: "customers",
    initialState,
    reducers: {
        setCustomers (state: CustomerStateData[], action: SetCustomersAction) {
            state = action.payload
            return state;
        },
        addCustomer(state: CustomerStateData[], action: SetCustomerAction) {
            state.unshift(action.payload)
        },
        resetCustomers() {
            return initialState;
        },
        deleteCustomer(state, action) {
            state.splice(action.payload,1)
        }
    },
})

export const {setCustomers, addCustomer, resetCustomers, deleteCustomer} = customerSlice.actions;
export default customerSlice.reducer;