import { css , StyleSheet} from "aphrodite";
import commonCss, { padding, colsGap, rowsGap } from "../../../commonCss/commonCss";
import { darkAmberColor, primaryColor, silverColor, softBlack, white } from "../../../theme";
import { AutoComplete, ConfigProvider } from 'antd';
import type { AutoCompleteProps } from 'antd';
import { useState } from "react";
import { CustomersRepository } from "../../../repositories/customerRepository";
import { setCustomers } from "../../../store/customersSlice";
import { DocumentData } from "firebase/firestore";
import { CustomersDTO } from "../../../models/customers";
import { useBillingHook } from "../../../hooks/useBillingHook";

// let nameOptions: {value: string}[] = [];

// let contactOptions: {value: string}[] = [];

const getDebounce = (callbackFunction: Function) => 
    setTimeout(() => {
        callbackFunction()
    }, 500);

let timer:any = null;



export default function CustomerDetails(){
    const {enterCustomerDetails} = useBillingHook();

    const [nameOptions, setNameOptions] = useState<{value: string}[]>([])
    const [contactOptions, setContactOptions] = useState<{value: string}[]>([])
 
    const [customers, setCustomers] = useState<CustomersDTO[]>([])
    const [ customerDetails, setCustomerDetails ] = useState<{name:string,contact:string,address:string,docId:string}>()

    const searchCustomer = (searchedValue: string)=>{
        
        CustomersRepository.searchCustomer(searchedValue).then((_customers: DocumentData[]) => {
            const _customersDTO = _customers.map((customer: DocumentData) => CustomersDTO.fromJSON(customer));
            setCustomers(_customersDTO);
            setNameOptions(_customersDTO.map(customer => ({ value: customer.name })));
            setContactOptions(_customersDTO.map(customer => ({ value: customer.contact })));

            

        });
    }

    function handleSelect(value:string, field: string){

        
        const selectedCustomer = (field=='name') ? customers.find(customer => customer.name === value) : customers.find(customer => customer.contact === value && customer.name === customerDetails?.name)
        if (selectedCustomer) {
            const { name, contact, address, docId } = selectedCustomer;
            setCustomerDetails({ name, contact, address, docId });
            enterCustomerDetails(customerDetails)
        }
    }

    function handleSearch(value: any){

        setCustomers([]);
        setNameOptions([]);
        setContactOptions([]);

        const searchedValue = value;
        if (timer) {
            clearTimeout(timer)
        }
        if(searchedValue!==''){
            timer = getDebounce(() => searchCustomer(searchedValue))
        }
    }

    return<div className={css(styles.frame, padding('1.6rem'))}>

    <div className={css(styles.title)}>Customer Details</div>  

    <div className={css(styles.input,commonCss.justifySpaceAround)}>

        <AutoComplete
            style={{ flex:1, maxWidth:'200px'}}
            options={nameOptions}
            placeholder="Name"
            filterOption={(inputValue, option) =>
                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            onSearch={handleSearch}
            onSelect={(string)=>handleSelect(string, 'name')}
        />
        <AutoComplete
            style={{ flex:1, maxWidth:'200px'}}
            options={contactOptions}
            placeholder="Contact"
            filterOption={true}
            onSearch={handleSearch}
            onSelect={(string)=>handleSelect(string, 'contact')}
        />
        <AutoComplete
            style={{ flex:1, maxWidth:'400px' }}
            placeholder="Address"
            value={customerDetails?.address}
        />

    </div>

</div>
}


const styles= StyleSheet.create({

    frame:{
        color: primaryColor,
        flex: 1,
        boxShadow: '0px 1px 5px #aaa',
        fontSize:'1.1rem',

    },
    
    title:{
        padding:'2rem',
        fontWeight:'bold',
        boxShadow: '0px 0px 1px #aaa',
        textAlign:'center',
    },

    input:{
        display: "flex",
        padding:'0.9rem',
        color:'grey',
        fontSize:'17px',
        justifyContent: 'space-between',
        alignItems: 'center',
        columnGap:'10px'
    },

})