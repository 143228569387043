import { Query } from "../library/query";

export class ProductTagRepository {
    private static productTagCollection = Query.getCollection('tags')

    static async getAllTagsCollection() {
        return await this.productTagCollection.getAllDocs();
    }

    static async incrementTagCountById(productTagNumber: string) {
        // parse the productTagNumber to get the id 
        // write a logic to find first number in the string productTagNumber
        console.log("updating tag count", productTagNumber);
        for (let i = 0; i < productTagNumber.length; i++) {

            if (!isNaN(parseInt(productTagNumber[i]))) {
                const id = productTagNumber.slice(i+2);
                console.log("id", id);
                return await this.productTagCollection.updateDocById(productTagNumber.slice(0, i+2), { count: parseInt(productTagNumber.slice(i+2)) });
            }
        }

        
    }

}